import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { toast } from 'react-toastify';
import { formatNumber } from 'helpers/generic';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as BankReconciliationService } from 'services/BankReconciliation';
import { default as TradeCategoriesService } from 'services/TradeCategories';

const TableMovements = styled.table`
    font-size: 12px;
    
    thead {

        tr {

            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3) {
            width: 100px;
            text-align: right;
        }
    }
`;

const Table = styled.table`
	font-size: 13px;

	thead {
		th {
			font-weight: 500;
		}
	}

	td, th {
		&:nth-child(2) {
			width: 100px;
			max-width: 100px;
			min-width: 100px;

			position: relative;
			text-align: right;

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
				text-align: right;
				padding-right: 5px;
				padding-left: 5px;
			}
		}
		&:nth-child(3) {
			width: 40px;
			text-align: center;
		}
	}

	td {
		&:first-of-type {
			padding: 0;
		}

		&.has-errors {
			background: var(--bs-danger);
		}
	}
`;

export default function TradeCategory(props) {
    let hideModal = props.hideModal;
    let entity = props.movements.filter((item) => item.class.indexOf('BankMovement') !== -1)[0] ?? null; 
    let type = props.type;

    let [categories, setCategories] = useState([]);

    const saveData = async () => {
        let result = await BankReconciliationService.save(
            'tradecategory', 
            props.movements, 
            {
                categories: categories.map(el => ({
                    id: el.category.id, amount: el.amount
                }))
            }
        );
        if ( result.status === 1 ) {
            toast.success('Movimientos conciliados');
            hideModal(true);
        } else {
            toast.error('Ha ocurrido un error al guardar');
        }
    }

    const addItem = useCallback(() => {
		let newCategories = categories ? [...categories] : [];
		newCategories.push({
			amount: categories.length === 0 ? entity.amount : ''
		});
		setCategories(newCategories);
	}, [categories, entity.amount]);

	const removeItem = (idx) => {
		let newCategories = [...categories];
		newCategories.splice(idx, 1);
		setCategories(newCategories);
	}

    const setAmount = (idx, value) => {
		let newCategories = [...categories];
		newCategories[idx].amount = value;
		setCategories(newCategories);
	}

    const loadCategories = async (input, callback) => {
		let categories = await TradeCategoriesService.list(type + 's', {
			search: input,
			no_paginate: true,
            with_breadcrumbs: true
		});
		
		let formatted = categories?.map(el => {
            let label = null;
            if ( el.level > 1 ) {
                let spaces = [];
                for (let i = 0; i < el.level - 1; i++) {
                    spaces.push(<React.Fragment key={i}>&nbsp;&nbsp;</React.Fragment>);
                }
                label = <>{spaces} <i className="bi bi-arrow-return-right"></i> {el.breadcrumbs}</>;
            } else {
                label = el.breadcrumbs;
            }

			return {
				value: el, 
				label: label
			};
		});

		callback(formatted);
	}

    const selectCategory = (idx, category) => {
        let newCategories = [...categories];
		newCategories[idx].category = category;
        setCategories(newCategories);
	}

    useEffect(() => {
        if ( categories.length <= 0 ) {
            addItem();
        }
    }, [categories, addItem])

    let categoriesTotal = categories.reduce((carry, item) => carry += item.category?.id ? parseFloat(item.amount) : 0, 0);
    let canReconciliate = categoriesTotal === entity.amount;

	return (
		<div className="row">
            <div className="col-md-12 mb-3">
                <label className="sbold">Movimiento bancario a conciliar</label>
                <TableMovements className="table table-sm table-bordered mb-0">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Concepto</th>
                            <th>Importe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{moment(entity.date).format('DD-MM-YYYY')}</td>
                            <td>{entity.concept ?? ''}</td>
                            <td>{formatNumber(entity?.amount ?? 0)}</td>
                        </tr>
                    </tbody>
                </TableMovements>
            </div>

            <div className="col-md-12">
                <label className="sbold">Categorías</label>
                <Table className="table table-sm table-bordered mb-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Importe</th>
                            <th>
                                <button className="btn-unstyled" onClick={() => addItem()}>
                                    <i className="bi bi-plus-circle-fill text-primary"></i>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { categories && categories.map((el, idx) => {
                            return (
                                <tr key={idx}>
                                    <td 
                                        // className={(errors['counterparts.'+idx+'.estimategroup_id'] ? 'has-errors' : '')}
                                    >
                                        <EmpoweredSelector
                                            load={(input, callback) => loadCategories(input, callback)}
                                            onChange={(value) => selectCategory(idx, value)}
                                            timeout={250}
                                            label={
                                                <div>
                                                    {el.category?.breadcrumbs ?? ''}&nbsp;
                                                </div>
                                            }
                                            placeholder="Categoría"
                                            showPlaceholderHelper={true}
                                            value={el.category?.id}
                                        />
                                    </td>
                                    <td 
                                        // className={(errors['counterparts.'+idx+'.amount'] ? 'has-errors' : '')}
                                    >
                                        <input 
                                            type="number" 
                                            className="no-arrows" 
                                            value={el.amount ?? ''} 
                                            onChange={(e) => setAmount(idx, e.target.value)} 
                                            min={-99999.99} 
                                            max={99999.99} 
                                            disabled={!categories[idx].category?.id}
                                        />
                                    </td>
                                    <td>
                                        <button className={'btn-unstyled text-danger'} onClick={() => removeItem(idx)}><i className="bi bi-x-circle-fill"></i></button>
                                    </td>
                                </tr>
                            );
                        })}
                        { (!categories || !categories.length) && <tr><td colSpan="100%" className="p-2">No se han añadido categorías</td></tr> }
                    </tbody>
                    { !canReconciliate &&
                        <tfoot>
                            <tr>
                                <td colSpan="100%" className="text-danger text-center">
                                    El importe a conciliar tiene que ser similiar al importe del movimiento
                                </td>
                            </tr>
                        </tfoot>
                    } 
                </Table>
            </div>
            
            <div className="col-md-12">
                <div className="text-end mt-3">
                    <button type="button" className="btn btn-primary text-white" disabled={!canReconciliate} onClick={() => saveData()}>Confirmar conciliación</button>
                </div>
            </div>
        </div>
	);
}


