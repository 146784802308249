import React, { useState } from 'react';
import styled from 'styled-components';
import BulkUploaderHelper from './BulkUploaderHelper';

const DropCristal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: 999999;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: white;
	font-size: 30px;

	button {
		font-size: 18px;
	}
`;

const BulkDragAndDropUpload = (props) => {
	const business = props.business;
	const permissions = props.permissions;
    const parentReload = props.parentReload;

    const [droppingFiles, setDroppingFiles] = useState(false);
    const [files, setFiles] = useState([]);

	if ( !permissions ) return <>{props.children}</>; // If not permissions, return children

    const dropHandler = (e) => {
		e.preventDefault();

		setDroppingFiles(false);

		let filesToUpload = [];

		if (e.dataTransfer.items) {
		    for (let i = 0; i < e.dataTransfer.items.length; i++) {
		      	if (e.dataTransfer.items[i].kind === 'file') {
		        	let file = e.dataTransfer.items[i].getAsFile();
		        	filesToUpload.push(file);
		      	}
		    }
	  	} else {
		    for (let i = 0; i < e.dataTransfer.files.length; i++) {
		    	let file = e.dataTransfer.files[i];
		    	filesToUpload.push(file);
		    }
	  	}

	  	// Clear
	  	removeDragData(e)

	  	// Upload
	  	setFiles(filesToUpload);
	}

	const removeDragData = (e) => {
		if (e.dataTransfer.items) { // Use DataTransferItemList interface to remove the drag data
		    e.dataTransfer.items.clear();
		} else { // Use DataTransfer interface to remove the drag data
			e.dataTransfer.clearData();
		}
	}

	const dragOverHandler = (e) => {
		e.preventDefault();
        if ( !e.dataTransfer.types.includes('Files') ) return; // Check if dragging files
		if ( files.length > 0 ) return; // Prevent drag if uploading

		setDroppingFiles(true);
	}

    return (
        <div
            onDrop={(e) => dropHandler(e)} 
            onDragOver={(e) => dragOverHandler(e)}
        >
            { droppingFiles && 
				<DropCristal>
					Suelta los archivos aquí
					<div>
						<button className="btn btn-link" onClick={() => setDroppingFiles(false)}>Cancelar</button>
					</div>
				</DropCristal> 
            }

            {files.length > 0 && 
				<BulkUploaderHelper 
					files={files} 
					close={() => {
						setFiles([]);
						parentReload();
					}} 
					business={business} 
				/>
			}

            {props.children}
        </div>
    )
}

export default BulkDragAndDropUpload;