import React, { useEffect, useState, useRef } from "react";
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import Invoice from './Invoice';
import Expense from './Expense';
import ProviderInvoice from './ProviderInvoice';
import TradeCategory from './TradeCategory';
import BankRemittance from './BankRemittance';

const ModalStyled = styled.div`
	background: rgba(0,0,0, 0.7);
`;

const NoDocumentOptions = styled.div`
	display: flex;

	button {
		width: 50%;
		margin: 10px;
		padding: 10px;
		font-size: 20px;
		line-height: 20px;

		small {
			display: block;
			font-size: 13px;
			line-height: 13px;
			margin-top: 10px;
		}
	}
`;

export default function ReconciliationModal(props) {
	const modalRef = useRef(null);

	const movements = props.movements;
	const closeCallback = props.closeCallback;
	const [type, setType] = useState('');
	const [unselectSelectedMovements, setUnselectSelectedMovements] = useState(false);

	useEffect(() => {
		let type = '';
		movements.forEach((el) => {
			if ( el.class === 'App\\Models\\Expense' ) {
				type = 'expense';
				return;
			}

			if ( el.class === 'App\\Models\\ProviderInvoice' ) {
				type = 'provider-invoice';
				return;
			}

			if ( el.class === 'App\\Models\\Invoice' ) {
				type = 'invoice';
				return;
			}

			// if ( el.class === 'App\\Models\\Receipt' ) {
			// 	type = 'receipt';
			// 	return;
			// }

			if ( el.class === 'App\\Models\\BankRemittance' ) {
				type = 'bankremittance';
				return;
			}
		});
		setType(type);
	}, [movements]);

	useEffect(() => {
		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
			if ( e.target !== modal._element ) return; // Fix to avoid close when a child modal is closed

           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			closeCallback(unselectSelectedMovements);

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);
		modal.show();
	}, [closeCallback, unselectSelectedMovements]);

	const hideModal = (unselectSelectedMovements = false) => {
		setUnselectSelectedMovements(unselectSelectedMovements);

		// Fix on timeout because we need to execute this after render
		setTimeout(() => {
			const modal = Modal.getInstance(modalRef.current);
			if ( modal ) modal.hide();
		}, 100);
	}

	let notSingleBankMovement = movements.filter((item) => item.class.indexOf('BankMovement') !== -1).length !== 1;

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Conciliación de movimientos seleccionados</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						{ type === '' && 
							<NoDocumentOptions> 
								<button 
									className="btn btn-sm btn-primary text-white" 
									onClick={() => setType('tradecategory-income')}
									disabled={notSingleBankMovement}
								>
									Seleccionar categoría de ingreso
									{notSingleBankMovement && <small>(solo un movimiento<br />bancario a la vez)</small>}
								</button>
								<button 
									className="btn btn-sm btn-primary text-white" 
									onClick={() => setType('tradecategory-expense')}
									disabled={notSingleBankMovement}
								>
									Seleccionar categoría de gasto
									{notSingleBankMovement && <small>(solo un movimiento<br />bancario a la vez)</small>}
								</button>
							</NoDocumentOptions> 	
						}
						
						{ type === 'provider-invoice' && <ProviderInvoice movements={movements} hideModal={hideModal} /> }
						{ type === 'expense' && <Expense movements={movements} hideModal={hideModal} /> }
						{ type === 'invoice' && <Invoice movements={movements} hideModal={hideModal} /> }
						{ type === 'tradecategory-income' && <TradeCategory type='income' movements={movements} hideModal={hideModal} /> }
						{ type === 'tradecategory-expense' && <TradeCategory type='expense' movements={movements} hideModal={hideModal} /> }
						{ type === 'bankremittance' && <BankRemittance movements={movements} hideModal={hideModal} /> }
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}


