import useStore from "store";

export const authUserPermission = (permission, business = null) => {
	// Get business and user from state
	business = business === null ? getBusiness() : business;
	const user = useStore.getState().user;
	if ( !user ) return false;
	if ( user.role === 'admin' ) return true;


	// Get auth user permissions from business
	let permissions = null;
	try {
		permissions = user.permissions[business.id] ?? null;
	} catch (e) {}
	if ( !permissions ) return false;

	// Check
	if ( permissions[permission] ) return true;
	return false;
}

export const getBusiness = () => {
	let business = {...useStore.getState().business};

	// Overwrite if exists from query params
	const params = new URLSearchParams(window.location.search);
	const business_id = parseInt(params.get('business_id'));
	if ( business_id && business_id !== business?.id ) {
		business = {
			id: business_id,
			name: 'business ' + business_id,
			from_query_param: true
		};
	}

	return business;
}
 
export function setBusiness(data, reload = false, clearHistory = true, redirect = null) {
	useStore.getState().dispatch({
		type: 'setBusiness',
		data: data,
		forceReload: reload ? Math.random() : null,
		clearHistory: clearHistory
	});
} 