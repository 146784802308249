import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import moment from "moment";
import { toast } from "react-toastify";
import { Modal } from 'bootstrap/dist/js/bootstrap';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as BankRemittancesService } from "services/BankRemittances";
import { CristalLoader } from "helpers/generic";

const ModalStyled = styled.div`

    & > button {
        padding: 0;
        line-height: 0;
        border: 0;
        background: transparent;
        color: var(--bs-primary);
    }

    .modal {
	    background: rgba(0, 0, 0, 0.4);
    }

`;

export default function AssociateBankRemittanceModal(props) {
	const modalRef = useRef(null);

    const selectedItems = props.selectedItems;
    const closeCallback = props.closeCallback;

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [remittance, setRemittance] = useState(null);

    useEffect(() => {
        const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

        modal.show();
    }, []);

    const loadRemittances = async (input, callback) => {
		let treasuries = await BankRemittancesService.list({
            completed: false,
			no_paginate: true,
		});
		
		let formatted = treasuries?.map((el) => {
			return {
				value: el, 
				label: (
					<div>
						Remesa {el.number_full}
                        <div><small>Fecha pago: {moment(el.payment_date).format('DD-MM-YYYY')}</small></div>
					</div>
				)
			};
		});
		callback(formatted);
	}

    const closeModal = (redirect = false) => {
        const modal = Modal.getInstance(modalRef.current);
		modal.hide();

        closeCallback(redirect);
    }

    const save = async () => {
        setErrors({});

        setLoading(true);
        let result = await BankRemittancesService.addReceipts(remittance.id, selectedItems.map(el => el.id));
        setLoading(false);

        if ( result.status ) {
			toast.info('Recibos asociados a la remesa');
            closeModal(true);
        } else {
            setErrors(result.errors);
			toast.error('Ha ocurrido un error al asociar recibos a la remesa');
        }
    }

	return (
        <ModalStyled>
            {loading && <CristalLoader />}
            <div className="modal" tabIndex="-1" ref={modalRef}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Asociar remesa bancaria</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal()} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-0">
                                        <EmpoweredSelector
                                            load={loadRemittances}
                                            onChange={(value) => setRemittance(value)}
                                            timeout={250}
                                            label={
                                                remittance ? 
                                                    <div>
                                                        {'Remesa ' + remittance.number_full}
                                                        <div><small>Fecha pago: {moment(remittance.payment_date).format('DD-MM-YYYY')}</small></div>
                                                    </div>
                                                    : 
                                                    ''
                                            }
                                            placeholder="- Remesa -"
                                            value={remittance?.id}
                                            noSearcheable={true}
                                        />
                                        { errors['remittance_id'] &&
                                            <div className="invalid-feedback d-block">{ errors['remittance_id'][0] }</div>
                                        }
                                    </div>
                                </div>
                                { errors['receipts'] &&
                                    <div className="invalid-feedback d-block">{ errors['receipts'][0] }</div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={'btn btn-sm btn-primary text-white'} disabled={!remittance?.id} onClick={() => save()}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyled>
	);
}