import axios from 'axios';
import { getBusiness } from 'helpers/business';

export default class Invoices {

	static axiosCancelToken = null;

	static list = (params, business_id = null) => {
		this.newAxiosCancelToken();

		if ( !params || !params.business_id ) {
			if ( !params ) params = {};
			params.business_id = business_id ?? getBusiness()?.id;
		}

		return axios.get('api/invoices/list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static get = (id, business_id = null) => {
		this.newAxiosCancelToken();

		return axios.get('api/invoices/get/' + id, {
			params: {
				business_id: business_id ?? getBusiness()?.id,
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static getNextInvoiceNumber = (serie_id, date, business_id = null) => {
		this.newAxiosCancelToken();

		return axios.get('api/invoices/get-next-number', {
			params: {
				business_id: business_id ?? getBusiness()?.id,
				serie_id: serie_id,
				date: date
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static save = (data, business_id = null) => {
		this.newAxiosCancelToken();

		if ( !data || !data.business_id ) {
			if ( !data ) data = {};
			data.business_id = business_id ?? getBusiness()?.id;
		}

		return axios.post('api/invoices/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data.errors ) return error.response.data;
		});	
	}

	static delete = (id, business_id = null) => {
		this.newAxiosCancelToken();

		return axios.delete('api/invoices/delete/' + id + '?business_id=' + (business_id ?? getBusiness()?.id), {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
	    	return true;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return false;
		});	
	}

	static getPdf = (id, business_id = null) => {
		this.newAxiosCancelToken();

		return axios.get('api/invoices/get-pdf/' + id, {
			params: {
				business_id: business_id ?? getBusiness()?.id,
			},
			responseType: 'blob',
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}