import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import BusinessChecker from 'components/BusinessChecker';
import { default as PaymentMethodsService } from 'services/PaymentMethods';

const Table = styled.table`
	th,td {
		&:nth-child(1) {
			width: 100px;
		}

		&:nth-child(2) {
		}

		&:nth-child(3) {
			width: 40px;
		}	
	}
`;

let searchTimeout = null;

export default function PaymentMethods() {
	const [queryParams] = useSearchParams();

	let [pmethods, setPmethods] = useState({});
	let [search, setSearch] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('name');
	let [visibleFilter, setVisibleFilter] = useState('');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setPmethods({...pmethods, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		const getPMethods = async () => {
			let methods = await PaymentMethodsService.list({
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
				visible: visibleFilter
			});

			setPmethods({...methods});
			setSkeletonRows(methods?.data?.length);
		}

		getPMethods();
	}, [page, search, sortField, sortDirection, visibleFilter]);

	return (
		<LoggedLayout>
			<BusinessChecker />

			<section>
				<div className="page-title">
					<h1>Métodos de pago</h1>

					<NavLink to="/payment-methods/add" className="btn btn-sm btn-light ms-auto">Nuevo método</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-8 mb-2 mt-2 mb-md-0 mt-md-0">
									
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									<select className="form-control form-control-sm" onChange={(e) => setVisibleFilter(e.target.value)} value={visibleFilter}>
										<option value="all">Ver todos</option>
										<option value="">Ver activos</option>
										<option value="archived">Ver archivados</option>
									</select>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'code'} onClick={() => sortTableClick('code')}>Código</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ pmethods.data?.length > 0 &&
											pmethods.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>{el.code ?? '-'}</td>
															<td>
																{el.name ?? '-'}
																{ el.deleted_at && 
																	<div><small className="text-danger">archivado</small></div>
																}
															</td>
															<td>
																<NavLink className="btn-table" to={'/payment-methods/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ pmethods.data && !pmethods.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ pmethods.data === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={pmethods?.current_page}
									max={pmethods?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


