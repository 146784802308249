import axios from 'axios';
import { getBusiness } from 'helpers/business';

export default class BankReconciliation {

	static axiosCancelToken = null;

	static uploadFile = (file, business_id = null) => {
		this.newAxiosCancelToken();

		let formData = new FormData();
		formData.append('file', file);
		formData.append('business_id', business_id ?? getBusiness()?.id);

		return axios.post('/api/bank-reconciliation/upload-file', formData, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	static getMovementsList = (id, params, business_id = null) => {
		this.newAxiosCancelToken();

		if ( !params || !params.business_id ) {
			if ( !params ) params = {};
			params.business_id = business_id ?? getBusiness()?.id;
		}

		return axios.get('/api/bank-reconciliation/' + id + '/movements-list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		  }).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	static remove = (id, business_id = null) => {
		this.newAxiosCancelToken();

		return axios.post('/api/bank-reconciliation/delete/' + id, {
            business_id: business_id ?? getBusiness()?.id,
        }, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return true;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;

			return false;
		});	
	}

	static save = (type, movements, extra, business_id = null) => {
		this.newAxiosCancelToken();
		
		let data = {
			type: type,
			movements: movements,
			business_id: business_id ?? getBusiness()?.id
		};
		if ( extra ) data.extra = extra;

		return axios.post('api/bank-reconciliation/save', data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data.errors ) return error.response.data;
		});	
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}