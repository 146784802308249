import axios from 'axios';
import { getBusiness } from 'helpers/business';

export default class Expenses {

	static axiosCancelToken = null;

	static list = (params, business_id = null) => {
		this.newAxiosCancelToken();

		if ( !params || !params.business_id ) {
			if ( !params ) params = {};
			params.business_id = business_id ?? getBusiness()?.id;
		}

		return axios.get('api/expenses/list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static get = (id, business_id = null) => {
		this.newAxiosCancelToken();

		return axios.get('api/expenses/get/' + id, {
			params: {
				business_id: business_id ?? getBusiness()?.id,
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static save = (data, business_id = null) => {
		this.newAxiosCancelToken();

		if ( !data || !data.business_id ) {
			if ( !data ) data = {};
			data.business_id = business_id ?? getBusiness()?.id;
		}

		let formData = new FormData();
		for(let i in data) {
			let el = data[i];
			if ( el === undefined || el === null ) continue;
			if ( typeof el === 'object' && !(el instanceof File) ) el = JSON.stringify(el);
			formData.append(i, el);
		}

		console.log(formData)

		return axios.post('api/expenses/' + (data.id ? 'edit/' + data.id : 'add'), formData, {
			headers: {
				'Content-Type': 'multipart/form-data' 
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data.errors ) return error.response.data;
		});	
	}

	static delete = (id, business_id = null) => {
		this.newAxiosCancelToken();

		return axios.delete('api/expenses/delete/' + id + '?business_id=' + (business_id ?? getBusiness()?.id), {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
	    	return true;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return false;
		});	
	}

	static getPdf = (id, business_id = null) => {
		this.newAxiosCancelToken();

		return axios.get('api/expenses/get-pdf/' + id, {
			params: {
				business_id: business_id ?? getBusiness()?.id,
			},
			responseType: 'blob',
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}