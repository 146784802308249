import axios from 'axios';

export default class BanksHelper {

	static axiosCancelToken = null;

	static getByIban = (iban) => {
		this.newAxiosCancelToken();

		return axios.get('api/bankshelper/get-bank-by-iban', {
			params: {
				iban: iban
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}