import styled from "styled-components";
import EmpoweredSelector from "components/EmpoweredSelector";
import CustomInput from "components/CustomInput";
import LabeledFrame from "components/LabeledFrame";
import { default as ClientsService } from 'services/Clients';
import { default as BanksHelperService } from 'services/BanksHelper';
import { useEffect } from "react";

const BankAccountsSelector = styled.div` 
    display: flex;
    align-items: center;

    .empowered-selector {
       
        .empowered-selector_label {
            font-weight: inherit;
            padding: 0;
            background: transparent;
            font-size: inherit;
            min-height: auto;

            .dropdown-toggle-icon {
                position: unset;
                margin-left: 10px;
                margin-top: 5px;
            }
        }
    }

    & > button {
        border: 0;
        padding: 0;
        background: none;
        color: var(--bs-danger);
        font-size: 15px;
        line-height: 0;
        margin-top: 5px;
        margin-left: 10px;

        &:hover {
            transform: scale(1.2);
        }
    }
`;

export default function BankAccountPanel(props) {
    let clientId = props.clientId;
    let data = props.data;
    let setData = props.setData;
    let setDataField = props.setDataField;
    let errors = props.errors;
    let canEdit = props.readOnly ? false : true;

    const loadBankAccounts = async (input, callback, args) => {
		let clients = await ClientsService.get(args.client_id);

		let formatted = clients?.bankaccounts.map((el) => {
			return {
				value: el, 
				label: el.iban
			};
		});
		callback(formatted);
	}
    
    useEffect(() => {
        if ( !data?.iban ) return;

        let getBic = async () => {
            let bank = await BanksHelperService.getByIban(data.iban);
            if ( bank?.bic ) setDataField('swift', bank.bic);
        }
        getBic();
    }, [data?.iban]);

    return (
        <LabeledFrame 
            label={
                <BankAccountsSelector>
                    <EmpoweredSelector
                        label="Cuenta bancaria"
                        load={(input, callback, args) => loadBankAccounts(input, callback, args)}
                        args={{client_id: clientId}}
                        onChange={(value) => setData(value)}
                        timeout={250}
                        value={data?.id}
                        noRemovable={true}
                        dropDownMenuWidth={'250px'}
                        disabled={!canEdit || !clientId}
                    />

                    { (data?.id && canEdit) && 
                        <button onClick={() => setData(null)}><i className="bi bi-x"></i></button>
                    }
                </BankAccountsSelector>
            }
            className="mb-3 mb-md-0"
        >
            <div className="row">
                { errors['bankaccount_id'] &&
                    <div className="invalid-feedback d-block mb-2 mt-0">{ errors['bankaccount_id'][0] }</div>
                }

                <div className="col col-static-80">
                    <div className="mb-2">
                        <CustomInput 
                            label="Swift / Bic" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('swift', e.target.value)} 
                            value={data?.swift ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['bankaccount_data.swift'] &&
                            <div className="invalid-feedback d-block">{ errors['bankaccount_data.swift'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-200">
                    <div className="mb-2">
                        <CustomInput 
                            label="Iban" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('iban', e.target.value)} 
                            value={data?.iban ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['bankaccount_data.iban'] &&
                            <div className="invalid-feedback d-block">{ errors['bankaccount_data.iban'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-120">
                    <div className="mb-2">
                        <CustomInput 
                            label="Fecha del mandato" 
                            type="date" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('mandate_date', e.target.value)} 
                            value={data?.mandate_date ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['bankaccount_data.mandate_date'] &&
                            <div className="invalid-feedback d-block">{ errors['bankaccount_data.mandate_date'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-180">
                    <div className="mb-2">
                        <CustomInput 
                            label="Identificador del mandato" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('mandate_identifier', e.target.value)} 
                            value={data?.mandate_identifier ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['bankaccount_data.mandate_identifier'] &&
                            <div className="invalid-feedback d-block">{ errors['bankaccount_data.mandate_identifier'][0] }</div>
                        }
                    </div>
                </div>
            </div>	
        </LabeledFrame>
    );
}