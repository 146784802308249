import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import LabeledFrame from 'components/LabeledFrame';
import PermissionsTable from './PermissionsTable';
import CustomInput from 'components/CustomInput';
import { default as UsersService } from 'services/Users';

export default function UsersForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		role: 'user',
		// communities_permissions: {}
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		if ( params.id ) {
			const getUser = async () => {
				setLoading(true);

				let user = await UsersService.get(params.id);
				if (user) setData({...user});

				setLoading(false);
			}
	
			getUser();
		}
	}, [params.id]);

	const deleteUser = async () => {
		const c = window.confirm('¿Quieres eliminar este usuario?');
		if ( !c ) return;

		let result = await UsersService.delete(data.id);
		if ( result ) {
			toast.success('Usuario borrado')
	    	navigate(-1);
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		let result = null;
		try {
			result = await UsersService.save(data);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			
			if ( goBack ) {
				if ( popup ) window.close();
				else navigate(-1);
			} else {
		    	setData(result.user);
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setPermissions = (permissions) => {
		setData({...data, businesses_permissions: permissions});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<React.Fragment>
			<Layout>
				{ loading && <CristalLoader /> }

				<section>
					<div className="page-title">
						<h1>Ficha de usuario</h1>
						<button onClick={() => popup ? window.close() : navigate(-1)} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
					</div>

					<div className="page-content">
						<div className="row justify-content-center">
							<div className="col-md-12">
								<div className="card border-0 shadow-sm">
									<div className="card-body">
										<div className="row">
											<div className="col-md-7">
												<LabeledFrame label="Datos básicos" className="mb-3">
													<div className="row">
														<div className="col-md-8">
															<div className="mb-2">
																<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
																{ errors.name &&
																	<div className="invalid-feedback d-block">{ errors.name[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-2">
																<CustomInput label="DNI" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('vatnumber', e.target.value)} value={data.vatnumber ?? ''} />
																{ errors.vatnumber &&
																	<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-2">
																<CustomInput label="Email" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('email', e.target.value)} value={data.email ?? ''} />
																{ errors.email &&
																	<div className="invalid-feedback d-block">{ errors.email[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-2">
																<CustomInput label="Teléfono" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('phone', e.target.value)} value={data.phone ?? ''} />
																{ errors.phone &&
																	<div className="invalid-feedback d-block">{ errors.phone[0] }</div>
																}
															</div>
														</div>

														<div className="col-md-12 mt-4"></div>

														<div className="col-md-8">
															<div className="form-check form-switch">
																<input className="form-check-input" type="checkbox" id="admin" onChange={(e) => setDataField('role', e.target.checked ? 'admin' : 'user')} checked={data.role === 'admin'} />
																<label className="form-check-label" htmlFor="admin">Usuario administrador</label>
															</div>
															<div style={{fontSize: '12px', lineHeight: '12px', color: 'var(--bs-gray-600)'}}>Los administradores pueden acceder al menú de configuración y añadir nuevas empresas</div>
														</div>

														<div className="col-md-4">
															<div className="mb-2">
																<CustomInput label="Contraseña" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('password', e.target.value)} value={data.password ?? ''} />
																{ data.id &&
																	<small className="text-secondary d-block" style={{fontSize: '10px', lineHeight: '12px'}}>Dejar en blanco para no cambiar la contraseña</small>
																}
																{ errors.password &&
																	<div className="invalid-feedback d-block">{ errors.password[0] }</div>
																}
															</div>
														</div>
													</div>
												</LabeledFrame>

												<LabeledFrame label="Dirección">
													<div className="row">
														<div className="col-md-10">
															<div className="mb-2">
																<CustomInput label="Calle" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('address', e.target.value)} value={data.address ?? ''} />
																{ errors.address &&
																	<div className="invalid-feedback d-block">{ errors.address[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-2">
															<div className="mb-2">
																<CustomInput label="C.P." type="text" className="form-control form-control-sm" onChange={(e) => setDataField('postalcode', e.target.value)} value={data.postalcode ?? ''} />
																{ errors.postalcode &&
																	<div className="invalid-feedback d-block">{ errors.postalcode[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-5">
															<div className="mb-2">
																<CustomInput label="Ciudad" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('city', e.target.value)} value={data.city ?? ''} />
																{ errors.city &&
																	<div className="invalid-feedback d-block">{ errors.city[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-5">
															<div className="mb-2">
																<CustomInput label="Provincia" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('province', e.target.value)} value={data.province ?? ''} />
																{ errors.province &&
																	<div className="invalid-feedback d-block">{ errors.province[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-2">
															<div className="mb-2">
																<CustomInput label="País" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('country', e.target.value)} value={data.country ?? ''} />
																{ errors.country &&
																	<div className="invalid-feedback d-block">{ errors.country[0] }</div>
																}
															</div>
														</div>
													</div>
												</LabeledFrame>
											</div>
											<div className="col-md-5">
												<LabeledFrame label="Empresas">
													<PermissionsTable 
														permissions={data.businesses_permissions} 
														setPermissions={(data) => setPermissions(data)} 
														principalUser={data.role === 'admin'}
													/>			
												</LabeledFrame>
											</div>
										</div>
									</div>
									<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
										<div className="row">
											<div className="col-6">
												{ data.id &&
													<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteUser()}>Eliminar</button>							
												}
											</div>
											<div className="col-6 text-end">
												<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
												<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		</React.Fragment>
	);
}