import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { jsonToArray } from 'helpers/generic';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import useStore from "store";
import { default as TaxesService } from 'services/Taxes';

const ItemsWrapper = styled.div`
	.c-header {
		display: flex;
		width: 100%;

		.c-item {
			border-top: 1px solid var(--bs-gray-400);
			font-weight: 500;

			.c-column {
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(5) {
					text-align: right;
				}
			}
		}

		@media (max-width: 992px) {
			display: none;
		}
	}

	.c-body {

		@media (max-width: 992px) {
			.c-item {
				background: var(--bs-gray-100);
				display: block;
				margin-bottom: 20px;
				padding: 5px;

				.c-column {
					border-right: 0 !important;
					max-width: 100% !important;
					width: 100% !important;

					input, textarea {
						position: relative;
						display: block;
						width: 100%;
						border: 1px solid var(--bs-gray-500);
						background: white;
					}

					& > button {
						position: relative;
						background: var(--bs-red);
						
						.bi {
							color: var(--bs-white);
						}
					}
				}

				small {
					display: block;
				}
			}
		}

		@media (min-width: 993px) {
			.c-item {
				border-top: 0;
			}
		}
	}

	.c-footer {

		.c-item {
			border: 0;

			.c-column {
				text-align: right;
				font-weight: 500;

				&:nth-child(1) {

				}

				&:nth-child(2) {
					min-width: 180px;
					max-width: 180px;
					background: var(--bs-gray-100);
				}

				&:nth-child(3) {
					min-width: 161px; // +1 because border
					max-width: 161px; // +1 because border
					background: var(--bs-gray-100);
				}

				&:nth-child(2),
				&:nth-child(3) {
					border-right: 1px solid var(--bs-gray-400);
					border-bottom: 1px solid var(--bs-gray-400);
				}

				@media (max-width: 992px) {
					&:nth-child(1) {
						display: none;
					}

					& {
						min-width: 50% !important;
						max-width: 50% !important;
						width: 50% !important;
					}
				}
			}
		}
	}

	.c-item {
		display: flex;
		width: 100%;
		min-height: 30px;
		border: 1px solid var(--bs-gray-400);
	}

	.c-column {
		width: 100%;
		padding: 5px;
		position: relative;

		&.has-errors {
			background: rgba(255, 0, 0, 0.2);
		}

		&:not(:last-of-type) {
			border-right: 1px solid var(--bs-gray-400);
		}

		&:nth-child(1) {

		}
		&:nth-child(2) {
			min-width: 120px;
			max-width: 120px;
		}
		&:nth-child(3) {
			min-width: 120px;
			max-width: 120px;
		}
		&:nth-child(4) {
			min-width: 180px;
			max-width: 180px;
		}
		&:nth-child(5) {
			min-width: 120px;
			max-width: 120px;
		}
		&:nth-child(6) {
			min-width: 40px;
			max-width: 40px;
		}

		& > small {
			display: none;
		}

		& > input,
		& > textarea {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			padding: 5px 10px;
			background: transparent;
			resize: none;
			line-height: 100%;

			@media (min-width: 963px) {
				border: 0;
			}

			&[type=number] {
				text-align: right;
			}
		}

		& > button {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
			background: var(--bs-white);
			color: var(--bs-red);
			font-size: 20px;

			&[disabled] {
				opacity: 0.5;
			}

			&:hover {
				&:not([disabled]) {
					background: var(--bs-red);
					color: var(--bs-white);
				}
			}
		}

		.taxes_selector {
			display: flex;
			justify-content: center;

			.empowered-selector {

				.empowered-selector_label {
					background: none;
					border: 0;
					font-size: 10px;
					padding: 0;

					.bi {
						position: relative;
						margin-left: 10px;
					}
				}
			}
		}

		.taxes_wrapper {

			.tax-selected {
				display: inline-block;
				padding: 3px 5px;
				font-size: 12px;
				background: var(--bs-gray-300);
				margin: 2px;
				border-radius: 3px;
				position: relative;

				button {
					background: none;
					color: var(--bs-red);
					border: 0;
					padding: 0;
					position: absolute;
					top: -8px;
					right: -5px;
				}
			}
		}
	}
`;

const itemTemplate = {
	id: null,
	concept: '',
	units: 0,
	price: 0,
	taxes: [],
	base: 0,
	total: 0
};

let firstItemSetted = false;

export default function Items(props) {
    const strictMode = useStore(state => state.strictMode);
    const dispatch = useStore(state => state.dispatch);

	const data = props.data;
	const setData = props.setData;
	const errors = props.errors;
	const firstItemTotal = props.firstItemTotal;
	const firstItemConcept = props.firstItemConcept;
	let readOnly = props.readOnly ?? false;

	let [taxes, setTaxes] = useState({});

	let lastRow = useRef(null);
	let lastRowNumber = useRef(null);

	// Disable react strict mode because it is incompatible firstItemTotal and firstItemConcept functionality
	useEffect(() => {
		if ( strictMode ) dispatch({
			type: 'setStrictMode',
			data: false
		});
	}, [strictMode, dispatch]);

	useEffect(() => {
		if ( lastRow.current ) {
			lastRow.current.focus();
			lastRow.current = null;
		}
	}, [data]);

	useEffect(() => {
		const getAsync = async () => {
			// Load taxes first to set default tax before push a new item
			let taxes = await TaxesService.list({
				no_paginate: true
			});
			
			let taxesFormatted = {};
			taxes.forEach((el) => {
				if ( el.default && !itemTemplate.taxes.length ) itemTemplate.taxes.push(el.id);
				taxesFormatted[el.id] = el;
			});
			setTaxes(taxesFormatted);

			// Set first line if new
			if ( !data.id ) {
				setData((prev) => {
					if ( !prev.id ) return ({...prev, items: [{...itemTemplate}]});
					return ({...prev});
				});
			}
		}
		getAsync();
	}, [setData]);

	const loadTaxes = async (input, callback) => {
		let taxes = await TaxesService.list({
			search: input,
			no_paginate: true,
		});

		let formatted = taxes.map((el) => {
			return {
				value: el, 
				label: <div>
							<div>{el.name}</div>
							<small>{el.percent}%</small>
					   </div>
			};
		});
		callback(formatted);
	}

	const addItem = () => {
		let newData = {...data};
		let newItem = JSON.parse(JSON.stringify(itemTemplate)); // Deep clone

		if ( data.provider && data.provider.tax ) newItem.taxes.push(data.provider.tax_id);
		newData.items.push({...newItem});
		
		setData(newData);

		lastRowNumber.current = newData.items.length - 1; // Needed to focus
	}

	const removeItem = (idx) => {
		// Remove item
		let newData = {...data};
		let newItems = [...newData.items];
		newItems.splice(idx, 1);
		newData.items = newItems

		// Calc invoice base
		let base = 0;
		newItems.forEach((el) => base += el.base);
		newData.base = isNaN(base) ? 0 : base;

		// Calc invoice total
		let total = 0;
		newItems.forEach((el) => total += el.total);
		newData.total = isNaN(total) ? 0 : total;

		// Update state
		setData(newData);
	}

	const setItemField = (idx, field, value) => {
		let newData = {...data};
		let newItems = [...newData.items];
		let item = {...newItems[idx]};

		// Set value
		item[field] = value;

		// Calcs
		if ( field === 'price' || (item.price && (field === 'units' || field === 'taxes')) ) {
			item.base = parseFloat(item.units * item.price);

			// Calc taxes
			item.taxes_calc = {};
			if ( item.taxes ) item.taxes.forEach((el) => {
				let tax = taxes[el];
				if ( tax ) {
					let taxAmount = item.base * (parseFloat(tax.percent) / 100);
					
					if ( isNaN(taxAmount) ) taxAmount = 0;
					item.taxes_calc[el] = taxAmount;
				}
			});

			item.total = (() => {
				let total = item.base;
				for(let i in item.taxes_calc) total += item.taxes_calc[i];
				return total;
			})();
		} else if ( field === 'total' || (item.total && (field === 'units' || field === 'taxes')) ) {
			item.base = item.total;

			item.taxes_calc = {};
			if ( item.taxes ) {
				item.taxes.forEach((el) => {
					let tax = taxes[el];
					if ( tax ) {
						item.base = item.total / ((parseFloat(tax.percent) / 100) + 1); // First calc base
						let taxAmount = item.base * (parseFloat(tax.percent) / 100);
						
						if ( isNaN(taxAmount) ) taxAmount = 0;
						item.taxes_calc[el] = taxAmount;
					}
				});
			}

			if ( item.units ) item.price = parseFloat(item.base / item.units).toFixed(2);
		}

		// Set new data
		newItems[idx] = item;
		newData.items = newItems;

		// Calc invoice base
		let base = 0;
		newItems.forEach((el) => base += parseFloat(el.base));
		newData.base = isNaN(base) ? 0 : base;

		// Calc invoice total
		let total = 0;
		newItems.forEach((el) => total += parseFloat(el.total));
		newData.total = isNaN(total) ? 0 : total;

		// Update state
		setData(newData);
	}
 
	const addTaxToItem = (idx, tax) => {
		let taxes = [...data.items[idx].taxes] ?? [];
		if ( taxes.indexOf(tax.id) === -1 ) taxes.push(tax.id);
		setItemField(idx, 'taxes', taxes); 
	}

	const removeTaxFromItem = (idx, taxId) => {
		let taxes = [...data.items[idx].taxes] ?? [];
		let taxIdx = taxes.indexOf(taxId);
		taxes.splice(taxIdx, 1);
		setItemField(idx, 'taxes', taxes); 
	}

	// Prepare total taxes
	let totalTaxes = {};
	data.items.forEach((el) => {
		if ( el.taxes && el.taxes.length > 0 ) {
			el.taxes.forEach((elT) => {
				if ( totalTaxes[elT] === undefined ) totalTaxes[elT] = 0;

				let tax = taxes[elT];
				if ( !tax ) return;

				let taxAmount = el.base * (parseFloat(tax.percent) / 100);
				if ( !isNaN(taxAmount) ) totalTaxes[elT] += taxAmount;
			});
		}
	});

	// If first items fields, set here
	useEffect(() => {
		if ( !data.items[0] || firstItemSetted || !firstItemConcept || !firstItemTotal ) return;

		// Set data
		data.items[0].concept = firstItemConcept;
		data.items[0].total = firstItemTotal;
		data.items[0].units = 1;
		setData((prev) => ({...prev, items: [...data.items]}));

		// Calc price, base and taxes
		setItemField(0, 'total', data.items[0].total);

		firstItemSetted = true;
	}, [firstItemConcept, firstItemTotal, data.items[0]]);

	return (
		<LabeledFrame 
			label={'Líneas'}
			buttons={
				!readOnly ?
					<button className="btn-unstyled text-white" onClick={() => addItem()}>
						<i className="bi bi-plus-circle-fill"></i>
					</button>
				:
				null
			}
			buttonsBackground={'primary'}
		>
			<ItemsWrapper>
				<div className="c-header">
					<div className="c-item">
						<div className="c-column">Concepto</div>
						<div className="c-column">Unidades</div>
						<div className="c-column">Precio</div>
						<div className="c-column">Impuestos</div>
						<div className="c-column">Total</div>
						<div className="c-column"></div>
					</div>
				</div>
				<div className="c-body">
					{ data.items && data.items.map((el, idx) => {
						return (
							<div key={idx} className="c-item">
								<div className={'c-column ' + (errors['items.'+idx+'.concept'] ? 'has-errors' : '')}>
									<small>Concepto</small>
									<textarea readOnly={readOnly} ref={lastRowNumber.current === idx ? lastRow : null} value={el.concept ?? ''} onChange={(e) => setItemField(idx, 'concept', e.target.value)} placeholder={'Escribe aquí'} />
								</div>
								<div className={'c-column ' + (errors['items.'+idx+'.units'] ? 'has-errors' : '')}>
									<small>Unidades</small>
									<input readOnly={readOnly} type="number" className="no-arrows" value={el.units ?? 0} onChange={(e) => setItemField(idx, 'units', e.target.value)} />
								</div>
								<div className={'c-column ' + (errors['items.'+idx+'.price'] ? 'has-errors' : '')}>
									<small>Precio</small>
									<input readOnly={readOnly} type="number" className="no-arrows" value={el.price ?? 0} onChange={(e) => setItemField(idx, 'price', e.target.value)} />
								</div>
								<div className={'c-column ' + (errors['items.'+idx+'.taxes'] ? 'has-errors' : '')}>
									<div className="taxes_selector">
										{ !readOnly && 
											<EmpoweredSelector
												load={loadTaxes}
												onChange={(value) => addTaxToItem(idx, value)}
												timeout={250}
												placeholder="Añadir impuesto"
											/>
										}
									</div>
									<div className={'taxes_wrapper'}>
										{ el.taxes &&
											el.taxes.map((elT, idxT) => {
												let tax = taxes[elT];
												if ( !tax ) return null;

												return (
													<span key={idxT} className="tax-selected">
														{tax.name}
														<button className="btn btn-sm p-0" disabled={readOnly} onClick={() => removeTaxFromItem(idx, elT)}><i className="bi bi-x-circle-fill"></i></button>
													</span>
												);
											})
										}
									</div>
								</div>
								<div className="c-column">
									<small>Total</small>
									<input readOnly={readOnly} type="number" className="no-arrows" value={el.total ?? 0}  onChange={(e) => setItemField(idx, 'total', e.target.value)} />
								</div>
								<div className="c-column">
									<button disabled={readOnly} onClick={() => removeItem(idx)}><i className="bi bi-x"></i></button>
								</div>
							</div>
						);
					})}

					{ !data.items.length && 
						<div className="c-item p-2">No se han añadido líneas</div>
					}
				</div>
				<div className="c-footer">
					<div className="c-item">
						<div className="c-column"></div>
						<div className="c-column">Base Imponible</div>
						<div className="c-column">{data.base?.toFixed(2)} €</div>
					</div>
					{ jsonToArray(totalTaxes).map((el, idx) => {
						return (
							<div key={idx} className="c-item">
								<div className="c-column"></div>
								<div className="c-column">{taxes[el.key]?.name}</div>
								<div className="c-column">{el.value.toFixed(2)} €</div>
							</div>
						);
					})}
					<div className="c-item">
						<div className="c-column"></div>
						<div className="c-column">Total</div>
						<div className="c-column">{data.total?.toFixed(2)} €</div>
					</div>
				</div>
			</ItemsWrapper>
		</LabeledFrame>
	);
}