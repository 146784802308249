import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import FieldSmallComment from 'components/FieldSmallComment';
import { openPopupWindow } from 'helpers/generic';
import { default as UsersService } from 'services/Users';

const TableStyled = styled.table`
	width: 100%;
	font-size: 12px;

	thead {
		th {
			font-weight: normal;
			line-height: 30px;
			
			&:not(:nth-child(1)) {
				text-align: center;
			}

			&:nth-child(2),
			&:nth-child(3) {
				width: 40px;
			}

			&:nth-child(4),
			&:nth-child(5) {
				width: 70px;
			}
		}
	}

	tbody {
		td {
			line-height: 15px;

			&:not(:nth-child(1)) {
				text-align: center;
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				text-align: center;
			}

			input[type=checkbox].chkbx {
				width: 15px;
				height: 15px;
			}	
		}
	}
`;

export default function PermissionsTable(props) {

	let [users, setUsers] = useState([]);
	let readOnly = props.readOnly;

	useEffect(() => {
		const loadUsers = async () => {
			let users = await UsersService.list({no_paginate: true});
			setUsers([...users]);
	    }
	    loadUsers();
	}, []);

	const setPermission = (user_id, permission, status) => {
		let permissions = {...props.permissions};
		let user_permissions = permissions[user_id] ?? {};

		user_permissions[permission] = status;
		permissions[user_id] = user_permissions;

		props.setPermissions(permissions);
	}

	const openUserWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	let rows = [];
	if ( users ) users.forEach((el, idx) => {
		let user_permission = props.permissions && props.permissions[el.id] ? props.permissions[el.id] : {};

		// // If admin
		// if ( el.role === 'admin' ) user_permission = {
		// 	view: true,
		// 	add: true,
		// 	edit: true,
		// 	delete: true
		// };

		rows.push(
			<tr key={idx}>
				<td>{el.name} <NavLink to={'/users/edit/' + el.id + '?popup=true'} onClick={(e) => openUserWindow(e)}><i className="bi bi-eye-fill"></i></NavLink></td>
				<td><input disabled={readOnly} className="chkbx" type="checkbox" onChange={(e) => setPermission(el.id, 'view', e.target.checked)} checked={user_permission.view ?? false} /></td>
				<td><input disabled={readOnly} className="chkbx" type="checkbox" onChange={(e) => setPermission(el.id, 'add', e.target.checked)} checked={user_permission.add ?? false} /></td>
				<td><input disabled={readOnly} className="chkbx" type="checkbox" onChange={(e) => setPermission(el.id, 'edit', e.target.checked)} checked={user_permission.edit ?? false} /></td>
				<td><input disabled={readOnly} className="chkbx" type="checkbox" onChange={(e) => setPermission(el.id, 'delete', e.target.checked)} checked={user_permission.delete ?? false} /></td>
			</tr>
		);
	});

	return (
		<React.Fragment>
			<TableStyled className="table table-sm table-bordered mb-2">
				<thead>
					<tr>
						<th>Usuario</th>
						<th>Ver</th>
						<th>Crear</th>
						<th>Modificar</th>
						<th>Eliminar</th>
					</tr>
				</thead>
				<tbody>
					{ rows }
				</tbody>
			</TableStyled>	
			<FieldSmallComment>Estos permisos se utilizan para que los usuarios puedan trabajar con ciertas secciones relacionadas con las empresas</FieldSmallComment>
		</React.Fragment>	
	);
}