import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import FieldSmallComment from 'components/FieldSmallComment';
import { openPopupWindow, jsonToArray } from 'helpers/generic';
import { default as BusinessesService } from 'services/Businesses';

const TableStyled = styled.table`
	width: 100%;
	font-size: 12px;

	thead {
		th {
			font-weight: normal;
			line-height: 30px;
			cursor: pointer;
			
			&:not(:nth-child(1)) {
				text-align: center;
			}

			&:nth-child(2),
			&:nth-child(3) {
				width: 40px;
			}

			&:nth-child(4),
			&:nth-child(5) {
				width: 70px;
			}
		}
	}

	tbody {
		td {
			line-height: 15px;

			&:not(:nth-child(1)) {
				text-align: center;
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				text-align: center;
			}

			input[type=checkbox] {
				width: 15px;
				height: 15px;
			}	
		}
	}
`;

export default function PermissionsTable(props) {

	let [permissions, setPermissions] = useState([]);
	let [businesses, setBusinesses] = useState([]);

	useEffect(() => {
		const getBusinesses = async () => {
			let businesses = await BusinessesService.list({
				no_paginate: true
			});
			setBusinesses([...businesses]);
		}

		getBusinesses();
	}, []);

	const setPermission = (business_id, permission, status) => {
		let permissions = {...props.permissions};
		let business_permissions = permissions[business_id] ?? {};

		business_permissions[permission] = status;
		permissions[business_id] = business_permissions;

		props.setPermissions(permissions);
	}

	const toggleAll = (permission) => {
		let permissions = {...props.permissions};
		
		let total = businesses.length;
		let active = jsonToArray(permissions).filter(el => el.value[permission]).length;

		for(let i in businesses) {
			let business = businesses[i];
			if ( permissions[business.id] === undefined ) permissions[business.id] = {view: false, add: false, edit: false, delete: false};
			permissions[business.id][permission] = active < total;
		}

		props.setPermissions(permissions);
	}

	const openBusinessWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	let rows = [];
	if ( businesses ) businesses.forEach((el, idx) => {
		let business_permission = props.permissions && props.permissions[el.id] ? props.permissions[el.id] : {};


		if ( props.principal_user ) business_permission = {
			view: true,
			add: true,
			edit: true,
			delete: true
		};

		rows.push(
			<tr key={idx}>
				<td>{el.name} <NavLink to={'/businesses/edit/' + el.id + '?popup=true'} onClick={(e) => openBusinessWindow(e)}><i className="bi bi-eye-fill"></i></NavLink></td>
				<td><input type="checkbox" onChange={(e) => setPermission(el.id, 'view', e.target.checked)} checked={business_permission.view ?? false} /></td>
				<td><input type="checkbox" onChange={(e) => setPermission(el.id, 'add', e.target.checked)} checked={business_permission.add ?? false} /></td>
				<td><input type="checkbox" onChange={(e) => setPermission(el.id, 'edit', e.target.checked)} checked={business_permission.edit ?? false} /></td>
				<td><input type="checkbox" onChange={(e) => setPermission(el.id, 'delete', e.target.checked)} checked={business_permission.delete ?? false} /></td>
			</tr>
		);
	});

	return (
		<React.Fragment>
			<TableStyled className="table table-sm table-bordered mb-2">
				<thead>
					<tr>
						<th>Comunidad</th>
						<th onClick={() => toggleAll('view')}>Ver</th>
						<th onClick={() => toggleAll('add')}>Crear</th>
						<th onClick={() => toggleAll('edit')}>Modificar</th>
						<th onClick={() => toggleAll('delete')}>Eliminar</th>
					</tr>
				</thead>
				<tbody>
					{ rows }
				</tbody>
			</TableStyled>	
			<FieldSmallComment>Estos permisos se utilizan para que el usuario pueda trabajar con ciertas secciones relacionadas con las comunidades. Por ejemplo: propietarios, inquilinos, propiedades...</FieldSmallComment>
		</React.Fragment>	
	);
}