import useStore from "store";

export const getUser = () => {
	return useStore.getState().user;
}
 
export function setUser(data, reload = false) {
	useStore.getState().dispatch({
		type: 'setUser',
		data: data,
		forceReload: reload ? Math.random() : null
	});
} 

export function checkRole(role) {
	let user = getUser();
	if ( user?.role === role ) return true;
	return false;
}