import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { getBusiness, authUserPermission } from 'helpers/business';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import { default as PaymentMethodsService } from 'services/PaymentMethods';

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function PaymentMethodsForm() {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();
	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				let method = await PaymentMethodsService.get(params.id);
				if (method) {
					setData({...method});
					setCanEdit(false);
				} else {
					setData((prev) => ({...prev, id: null}));
				}

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data, 'paymentmethod');
		};
	}, [data]);

	const deleteMethod = async () => {
		const c = window.confirm('¿Quieres ' + (data.is_used ? 'archivar' : 'eliminar') + ' este método?');
		if ( !c ) return;

		let result = await PaymentMethodsService.delete(data.id);
		if ( result ) {
			toast.success('Método borrado')

			if ( popup ) {
				data = null; // Set data to null to parentWindowProxyCallback
				window.close();
			} else navigate('/payment-methods');
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}
	const undeleteMethod = async () => {
		const c = window.confirm('¿Quieres desarchivar este método?');
		if ( !c ) return;

		let result = await PaymentMethodsService.undelete(data.id);
		if ( result ) {
			toast.success('Método desarchivado')
	    	navigate('/payment-methods');
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		let result = null;
		try {
			result = await PaymentMethodsService.save(data, getBusiness()?.id);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');

			if ( popup && goBack ) {
				if ( parentWindowProxyCallback ) data = result.method; // Fix because parent needs id and update state function on previous line from this line update state async
				window.close();
				return;
			}

			if ( goBack ) navigate('/payment-methods');
			else {
		    	navigate('/payment-methods/edit/' + result.method.id + (popup ? '?popup=true' : ''));
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de método de pago</h1>
					<button onClick={() => popup ? window.close() : navigate('/payment-methods')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className={'row ' + (popup ? 'justify-content-center mt-3' : '')}>
						<div className="col-lg-6">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="row">
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput label="Código" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} readOnly={!canEdit} />
													{ errors.code &&
														<div className="invalid-feedback d-block">{ errors.code[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-9">
												<div className="mb-2">
													<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} readOnly={!canEdit} />
													{ errors.name &&
														<div className="invalid-feedback d-block">{ errors.name[0] }</div>
													}
												</div>
											</div>
										</div>	
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-4">
											{ ( data.id && authUserPermission('delete') ) &&
												<React.Fragment>
													{data.deleted_at ?
														<button className="btn btn-sm btn-link text-success" tabIndex="-1" onClick={() => undeleteMethod()}>Desarchivar</button>
														:
														<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteMethod()}>{data.is_used ? 'Archivar' : 'Eliminar'}</button>							
													}
												</React.Fragment>
											}
										</div>
										<div className="col-8 text-end">
											{ (data.id && authUserPermission('edit') || authUserPermission('add') ) &&
												<>
													<div className="dropdown d-inline">
														<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
															Acciones
														</button>
														<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
															<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
															<li><hr className="dropdown-divider" /></li>

															<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
															<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
														</ActionsContextMenu>
													</div>

													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>					
												</>
											}		
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}