import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams, useParams } from "react-router-dom";
import moment from 'moment';
import styled from 'styled-components';
import PopupLayout from "layouts/PopupLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { default as CustomFieldsService } from 'services/CustomFields';

const Table = styled.table`
	th,td {
		@media (min-width: 768px) {
			&:nth-child(2) {
				width: 50px;
			}

			&:nth-child(3) {
				width: 100px;
			}

			&:nth-child(4) {
				width: 150px;
			}

			&:nth-child(5) {
				width: 40px;
			}
		}
		
		@media (max-width: 768px) {
			&:nth-child(3) {
				display: none;
			}
		}
	}
`;
let searchTimeout = null;

export default function CustomFields() {
	const [queryParams] = useSearchParams();
	const params = useParams();
	const type = params.type;

	let [fields, setFields] = useState({});
	let [search, setSearch] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('order');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setFields({...fields, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		const getCustomFields = async () => {
			let customfields = await CustomFieldsService.list(type, {
				page: page,
					search: search,
					sort: sortField,
					direction: sortDirection,
			});

			setFields({...customfields});
			setSkeletonRows(customfields?.data?.length);
		}
		getCustomFields();
	}, [type, page, search, sortField, sortDirection]);

	return (
		<PopupLayout>
			<section>
				<div className="page-title">
					<h1>Campos personalizados</h1>

					<NavLink to={'/custom-fields/'+type+'/add'} className="btn btn-sm btn-light ms-auto">Nuevo campo</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="text" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-9">
									
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'order'} onClick={() => sortTableClick('order')}>Orden</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'fieldtype'} onClick={() => sortTableClick('fieldtype')}>Tipo de campo</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'created_at'} onClick={() => sortTableClick('created_at')}>Añadido el</ThSortable>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ fields.data?.length > 0 &&
											fields.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>{el.name ?? '-'}</td>
															<td>{el.order ?? '-'}</td>
															<td>{el.fieldtype === 'text' ? 'Texto' : ''}{el.fieldtype === 'link' ? 'Enlace' : ''}</td>
															<td>
																{ moment(el.created_at).format('DD-MM-YYYY') }
																&nbsp;<small>{ moment(el.created_at).format('HH:mm') }</small>
															</td>
															<td>
																<NavLink className="btn-table" to={'/custom-fields/'+type+'/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ fields.data && !fields.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ fields.data === undefined && <TrSkeleton rows={skeletonRows} columns={4} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end" id="footer-fixed">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={fields?.current_page}
									max={fields?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</PopupLayout>
	);
}


