import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import moment from "moment";
import CustomInput from 'components/CustomInput';
import { Modal } from 'bootstrap/dist/js/bootstrap';

const ModalStyled = styled.div`

    & > button {
        padding: 0;
        line-height: 0;
        border: 0;
        background: transparent;
        color: var(--bs-primary);
    }

    .modal {
	    background: rgba(0, 0, 0, 0.4);
    }

`;

export default function BulkCopyModal(props) {
	const modalRef = useRef(null);

    const selectedItems = props.selectedItems;
    const closeCallback = props.closeCallback;

    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [generateInvoices, setGenerateInvoices] = useState(false);
    
    useEffect(() => {
        const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

        modal.show();
    }, []);


    const closeModal = (saveData = false) => {
        const modal = Modal.getInstance(modalRef.current);
		modal.hide();

        closeCallback(saveData ? {date, generateInvoices} : null);
    }

    const receiptsWithoutInvoiceWarning = selectedItems.filter(el => !el.invoice_id).length > 0;

	return (
        <ModalStyled>
            <div className="modal" tabIndex="-1" ref={modalRef}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Copiar recibos</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <CustomInput label="Fecha de vencimiento" type="date" className="form-control form-control-sm" value={date ?? ''} onChange={(e) => setDate(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-0">
                                        <div className="form-check mb-0 d-flex align-items-center">
                                            <input 
                                                className="form-check-input me-2" 
                                                type="checkbox" 
                                                id="generate-invoice" 
                                                checked={generateInvoices} 
                                                onChange={(e) => setGenerateInvoices(e.target.checked)} 
                                            />
                                            <label className="form-check-label mt-1" htmlFor="generate-invoice">
                                                Generar facturas
                                            </label>
                                        </div>
                                    </div>
                                    {(receiptsWithoutInvoiceWarning && generateInvoices) &&
                                        <div className="invalid-feedback d-block">
                                            Algunos recibos seleccionados no tienen facturas asociadas. Solo se generarán nuevas facturas de los recibos que tienen facturas asociadas.
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={'btn btn-sm btn-primary text-white ' + (!date ? 'disabled' : '')} onClick={() => closeModal(true)}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyled>
	);
}