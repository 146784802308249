import axios from 'axios';

export default class Files {

	static axiosCancelToken = null;

	static download = (id) => {
		this.newAxiosCancelToken();

		return axios.get('/api/documents/download-file/' + id, {
			responseType: 'blob',
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}