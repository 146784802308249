import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import moment from "moment";
import { toast } from "react-toastify";
import CustomInput from 'components/CustomInput';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { default as  InvoicesService } from "services/Invoices";

const ModalStyled = styled.div`

    & > button {
        padding: 0;
        line-height: 0;
        border: 0;
        background: transparent;
        color: var(--bs-primary);
    }

    .modal {
	    background: rgba(0, 0, 0, 0.4);

        .modal-dialog {
            max-width: 250px;
        }
    }

`;

export default function BulkCopyModal(props) {
	const modalRef = useRef(null);

    const selectedItems = props.selectedItems;
    const closeCallback = props.closeCallback;

    const [invoices, setInvoices] = useState([]);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [dueDate, setDueDate] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

        modal.show();
    }, []);

    useEffect(() => {
        setInvoices([...selectedItems]);
    }, [selectedItems]);

    const closeModal = () => {
        const modal = Modal.getInstance(modalRef.current);
		modal.hide();

        closeCallback();
    }

    const save = async () => {
        setLoading(true);
        setErrors(null);

        let withErrors = [];
        let idx = 0;
		for await (let item of invoices) {
            let invoice = await InvoicesService.get(item.id);

			// Reset data
            let newInvoice = {...invoice};
			newInvoice.id = null;
			newInvoice.date = date;
			newInvoice.due_date = dueDate;
            newInvoice.number = (await InvoicesService.getNextInvoiceNumber(newInvoice.serie_id, date)).number;
            newInvoice.items.forEach(el => el.id = null); // Reset ids of items
			
			let result = await InvoicesService.save(newInvoice);

            if ( !result.status ) withErrors.push(invoice);
            else { // If success, remove from array to prevent save again if an error occurs and user press save again
                let newInvoices = [...invoices];
                newInvoices.splice(idx, 1);
                setInvoices(newInvoices);
            }

            idx++;
		}

        setLoading(false);


        if ( withErrors.length === 0 ) {
            closeModal();
        } else {
            let errors = [];
            withErrors.forEach(el => {
                errors.push('Factura ' + el.number_full + ' no copiada. Revisa la fecha.');
            });
            setErrors(errors);
        }
	}

	return (
        <ModalStyled>
            <div className="modal" tabIndex="-1" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Copiar facturas</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal()} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-2">
                                        <CustomInput label="Fecha" type="date" className="form-control form-control-sm no-arrows" value={date ?? ''} onChange={(e) => setDate(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-0">
                                        <CustomInput label="Fecha de vencimiento" type="date" className="form-control form-control-sm no-arrows" value={dueDate ?? ''} onChange={(e) => setDueDate(e.target.value)} />
                                    </div>
                                </div>
                                {errors &&
                                    <div className="col-md-12">
                                        <div className="text-primary mt-2 mb-2">Se han copiado las facturas seleccionadas excepto algunas</div>
                                        {errors.map(el => {
                                            return <div className="text-danger">- {el}</div>;
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={'btn btn-sm btn-primary text-white ' + (!date || loading ? 'disabled' : '')} onClick={save}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyled>
	);
}