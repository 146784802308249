import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/min/moment-with-locales';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import Row from './FamilyRow';
import { default as PrevisionsService } from 'services/Previsions';

const FamilyStyled = styled.div`
    margin-bottom: 20px;

    .labeled-frame-label {
        position: relative;
        height: 30px;
        min-width: 150px;
        width: auto;
        padding: 0;
        
        input[type=text] {
            background: transparent;
            min-width: 100%;
            width: 100%;
            height: 100%;
            border: 0;
            outline: none;
            padding: 0 10px;
            font-weight: bold;
            field-sizing: content;

            &.error {
                color: white;

                &::placeholder {
                    color: rgba(255,255,255,0.5);
                }
            }
        }

        button {
            border: 0;
            padding: 0;
            color: white;
            background: var(--bs-danger);
            font-size: 15px;
            line-height: 0;
            border-radius: 50%;

            position: absolute;
            top: -7.5px;
            right: -7.5px;

            &:hover {
                transform: scale(1.2);
            }
        }
    }

    .table-wrapper {
        overflow-y: auto;
    }

    table {

        thead, tfoot {

            tr {

                &:first-child > th:first-child {
                    position: sticky;
                    left: 0;
                    background: var(--bs-gray-100);

                    button {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 0;
                        background: transparent;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            transform: scale(1.03);
                        }
                    }
                }

                th {
                    text-transform: capitalize;
                    text-align: center;
                    vertical-align: middle;

                    &.row-name {
                        --width: 250px;
                        min-width: var(--width);
                        max-width: var(--width);
                    }

                    &.day {
                        --width: 50px;
                        min-width: var(--width);
                        max-width: var(--width);
                    }

                    &.month {
                        --width: 300px;
                        min-width: var(--width);
                        max-width: var(--width);
                    }

                    &.month-sub {
                        width: 33%;
                        font-weight: 500;
                    }
                }
            }
        }

        tfoot {

            tr {

                th {
                    z-index: 2;
                    font-weight: 600;
                }

                td {
                    position: relative;

                    input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        background: transparent !important;
                        border: 0;
                        padding: 0;
                        outline: none;
                        font-weight: 600;
                    }
                }
            }
        }

        thead, tbody, tfoot {

            tr {

                th, td {

                    &.accumulated {
                        background: var(--bs-gray-100);
                    }
                }
            }
        }
    }	
`;

const Family = (props) => {
    const { data, setData, remove, canEdit, months, previsionId, errors } = props;

    const [realAmounts, setRealAmounts] = useState([]);

    useEffect(() => {
        let getRealAmounts = async () => {
            let categoriesIds = data.rows.map(row => row.category?.id);
            if ( !categoriesIds.length || !previsionId ) return;
        
            let realAmounts = await PrevisionsService.getRealAmountFromCategories(previsionId, categoriesIds);
            setRealAmounts(realAmounts);
        }
        getRealAmounts();
    }, [data.rows, previsionId]);

    const setField = (field, value) => {
        const newData = {...data};
        newData[field] = value;
        setData(newData);
    }

    const addRow = () => {
		let newData = {...data};
		newData.rows.push({
			id: null,
			name: '',
			order: (newData.rows.length ?? 0) + 1,
			monthsAmount: (() => {
                let mA = {};
                months.forEach((month) => {
                    mA[month] = {
                        amount: null
                    }
                });
                return mA;
            })(),
            recentlyCreated: true
		});
		setData(newData);
	}

    const setRowData = (idx, rowData) => {
        let newData = {...data};
        newData.rows[idx] = rowData;
        setData(newData);
    }  

    const removeRow = (idx) => {
        let newData = {...data};
        newData.rows = newData.rows.filter((p, i) => i !== idx);
        setData(newData);
    }

    let monthsData = months.map((month) => {
        return {
            numeric: month,
            name: moment('2024-' + ((month < 10 ? '0' : '') + month) + '-01').locale('es').format('MMMM'),
        };
    });

    let accumulated = data.rows && data.rows.map(row => {
        // Prevision
        let prevision = 0;
        Object.keys(row.monthsAmount).forEach(key => {
            let amount = parseFloat(row.monthsAmount[key]?.amount ?? 0);
            if ( isNaN(amount) ) amount = 0;
            prevision += amount;
        });

        // Real
        let real = 0;
        if ( realAmounts[row.category?.id] ) {
            Object.keys(realAmounts[row.category?.id]).forEach(key => {
                let amount = parseFloat(realAmounts[row.category?.id][key] ?? 0);
                if ( isNaN(amount) ) amount = 0;
                real += amount;
            });
        }
        
        // Diff
        let diff = real - prevision;

        return { prevision, real, diff };
    });

    return (
        <FamilyStyled>
            <LabeledFrame 
                className="family"
                label={
                    <>
                        <input type="text" className={errors['name'] ? 'error' : ''} placeholder={'Nombre de la familia'} value={data.name ?? ''} onChange={(e) => setField('name', e.target.value)} readOnly={!canEdit} />
                        {canEdit && <button onClick={() => remove()}><i className="bi bi-x"></i></button>}
                    </>
                }
                background={Object.keys(errors).length > 0 ? 'danger' : 'gray-400'}
            >
                { errors['name'] &&
                    <div className="invalid-feedback d-block mb-3">
                        { errors['name'][0] }
                    </div>
                }
                { errors['rows'] &&
                    <div className="invalid-feedback d-block mb-3">
                        { errors['rows'][0] }
                    </div>
                }
                <div className="table-wrapper" id={'ftw-' + uuidv4()}>
                    <table className="table table-sm table-bordered">
                        <thead>
                            <tr>
                                <th rowSpan="2" className="row-name">
                                    {canEdit && <button onClick={() => addRow()}><i className="bi bi-plus-circle-fill"></i>&nbsp;Añadir previsión</button>}    
                                </th>
                                <th rowSpan="2" className="day">Día</th>
                                { [...monthsData].map((month) => {
                                    return (
                                        <th key={'month-' + month.numeric} className={'month ' + (month.numeric === 13 ? 'accumulated' : '')} colSpan="3">{month.name}</th>
                                    );
                                })}
                                <th colSpan="3" className="month accumulated">Acumulado</th>
                            </tr>
                            <tr>
                                { [...monthsData].map((month) => {
                                    return (
                                        <React.Fragment key={'month-' + month.numeric + '-sub'}>
                                            <th className={'month-sub'}>Previsto</th>
                                            <th className={'month-sub'}>Real</th>
                                            <th className={'month-sub'}>Diferencia</th>
                                        </React.Fragment>
                                    );
                                })}

                                <th className={'month-sub accumulated'}>Previsto</th>
                                <th className={'month-sub accumulated'}>Real</th>
                                <th className={'month-sub accumulated'}>Diferencia</th>
                            </tr>
                        </thead>
                        <tbody>
                            { data.rows && data.rows.map((row, idx) => {
                                const realAmountsSingleRow = realAmounts[row.category?.id] ?? null;

                                return (
                                    <Row 
                                        key={'row' + idx}
                                        data={row}
                                        setData={(data) => setRowData(idx, data)}
                                        realAmounts={realAmountsSingleRow}
                                        remove={() => removeRow(idx)}
                                        canEdit={canEdit}
                                        months={months}
                                        accumulated={accumulated[idx] ?? null}
                                        errors={(() => {
                                            let filteredErrors = {};

                                            let keys = Object.keys(errors ?? {}).filter(k => k.indexOf('rows.' + idx + '.') !== -1);

                                            for(let k of keys) {
                                                filteredErrors[k.replace('rows.' + idx + '.', '')] = errors[k];
                                            }
                                            
                                            return filteredErrors;
                                        })()}
                                    />
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Total</th>
                                <th></th>
                                { [...monthsData].map(month => {
                                    let prevision = data?.rows?.reduce((acc, row) => {
                                        let amount = parseFloat(row.monthsAmount[month.numeric]?.amount ?? 0);
                                        return acc + (!isNaN(amount) ? amount : 0);
                                    }, 0);

                                    let real = data?.rows?.reduce((acc, row) => {
                                        let amount = parseFloat(realAmounts && realAmounts[row.category?.id] && realAmounts[row.category?.id][month.numeric] ? realAmounts[row.category?.id][month.numeric] : 0);
                                        return acc + (!isNaN(amount) ? amount : 0);
                                    }, 0);

                                    let diff = real ? real - prevision : 0;

                                    return (
                                        <React.Fragment key={'month-' + month.numeric + '-total'}>
                                            <td><input type="number" className="no-arrows" step="0.01" value={prevision.toFixed(2)} readOnly /></td>
                                            <td><input type="number" className="no-arrows" step="0.01" value={real.toFixed(2)} readOnly /></td>
                                            <td><input type="number" className="no-arrows" step="0.01" value={diff.toFixed(2)} readOnly /></td>
                                        </React.Fragment>
                                    );
                                })}
                                <td className="accumulated">
                                    <input type="number" className="no-arrows" value={parseFloat(accumulated.reduce((carry, el) => carry + el.prevision, 0)).toFixed(2)} readOnly />
                                </td>
                                <td className="accumulated">
                                    <input type="number" className="no-arrows" value={parseFloat(accumulated.reduce((carry, el) => carry + el.real, 0)).toFixed(2)} readOnly />
                                </td>
                                <td className="accumulated">
                                    <input type="number" className="no-arrows" value={parseFloat(accumulated.reduce((carry, el) => carry + el.diff, 0)).toFixed(2)} readOnly />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </LabeledFrame>
        </FamilyStyled>
    );
}

export default Family;