import React from 'react';
import LoggedLayout from 'layouts/LoggedLayout';

export default function Dashboard() {

	return (
		<LoggedLayout>
			<div className="page-title">
				{/* <h1>
					Dashboard&nbsp;
					{ getCommunity()?.id &&
						<React.Fragment>
							de 
							<span className="badge bg-white text-primary mx-2">{getCommunity()?.name}</span>
							<span className="badge bg-white text-primary">{getCommunity()?.vatnumber}</span>
						</React.Fragment>
					}
				</h1> */}
			</div>

			<div className="page-content">
				<div className="row">
					<div className="col-md-12">
						No hay nada por ahora
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


