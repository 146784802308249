import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import { getBusiness, setBusiness } from 'helpers/business';
import { getUser, checkRole } from 'helpers/user';
import { isTouchEnabled, openPopupWindow } from 'helpers/generic';
import useStore from "store";
import Sidebar from 'layouts/Sidebar';
import EmpoweredSelector from 'components/EmpoweredSelector';
import LabeledFrame from 'components/LabeledFrame';
import { default as BusinessesService } from 'services/Businesses';

const Layout = styled.div`
	height: 100%;
	position: relative;

	@media screen and (min-width: 500px) {
		max-width: 1920px;
		margin: 0 auto;
	}
`;

const Navbar = styled.nav`
	@media screen and (min-width: 500px) {
		max-width: 1920px;
		margin: 0 auto;
	}

	background: white;
	box-shadow: 0px 2px 10px rgb(1 41 112 / 10%);
	z-index: 21;

	.navbar-brand {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		font-family: 'Open Sans', sans-serif;
		color: var(--bs-body-color);
		margin-right: 0;
		min-width: 250px;

		img#logo {
			max-height: 30px;
			max-width: 150px;
		}

		.badge {
			font-weight: lighter;
			font-size: 10px;
		}

		@media (max-width: 992px) {
			justify-content: start;
			padding-left: 20px;
		}
	}

	.btn-navbar-toggler {
		display: none;

		&.desktop {
			margin-left: 20px;
			padding: 0;

			@media (min-width: 993px) {
				display: inline;
			}
		}

		&.mobile {
			@media (max-width: 992px) {
				display: inline;
			}
		}		
	}

	.business_selector_wrapper {
		display: flex;
		width: 350px;
		
		& > .empowered-selector {
			margin-left: 10px;
			width: 100%;

			.business_selected_label {
				display: flex;
				width: 100%;

				.name {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					display: inline-block;
					width: 75%;
				}

				.name_short {
					display: inline-flex;
					align-items: center;
				}
			}
		}

		& > a {
			/* border: 1px solid var(--bs-gray-300); */
			border-left: 0;
			background: var(--bs-light);
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		@media (max-width: 992px) {
			width: 95%;
			margin: 5px auto;
			padding-left: 0px;
		}
	}

	.navbar-nav {
		@media (max-width: 992px) {
			margin: 0 auto;
			margin-top: 5px !important;
			margin-bottom: 0 !important;

			&:first-of-type {
				.dropdown-menu {
					left: 0;
					right: auto;
				}
			}

			&:last-of-type {
				.dropdown-menu {
					right: 0;
    				left: auto;
				}
			}

			.dropdown-menu {
				position: absolute;
			}

			.nav-link {
				padding: 0;
			}
		}
	}
`;

const DropdownTitle = styled.div`
	display: inline-flex;
	align-items: center;

	.bi {
		color: var(--bs-primary);
		font-size: 18px;
		line-height: var(--bs-body-font-size);
		margin-right: 5px;
	}
`;

const UserTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
	user-select: none;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 500;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 25px;
		height: 25px;
		font-size: 15px;
		background: var(--bs-gray-700);
		color: white;
		border-radius: 50%;
		margin-left: 10px;
	}
`;


const Main = styled.main`
	&:not(.is-mobile) {
		margin-left: 80px;

		&.sidebar-visible {
			margin-left: 250px;
		}
	}
	
	transition: all 0.3s;
	position: relative;	

	.page-title {
		--top: 45px;

		display: flex;
		background: var(--bs-blue);
		margin-top: var(--top);
		padding: 20px;
		border-bottom: 1px solid var(--bs-gray-300);
		align-items: center;
		position: sticky;
		top: var(--top);
		z-index: 3;

		h1 {
			display: flex;
			align-items: center;
			max-width: 1500px;

			color: white;
			font-size: 25px;
			line-height: 25px;
			margin: 0;
		}
	}

	.page-content {
		padding: 20px;
		/* max-width: 1500px; */
	}

	.card-footer {
		position: sticky;
		bottom: 0;
		background: #f7f7f7;
	}

	@media (max-width: 992px) {
    	& {
    		margin-left: 0 !important;
    	}
    }
`;

const ConfigurationDropdownWrapper = styled.div`
	.col-md-6 {
		@media (max-width: 768px) {
			&:first-of-type {
				margin-bottom: 10px;
			}
		}
	}
	ul.dropdown-menu {
		min-width: 450px;

		@media (max-width: 550px) {
			min-width: 280px !important;
		}

		.dropdown-item {
			border-radius: 3px;
			overflow: hidden;
			font-size: 13px;
		}
	}
`;

let axiosCancelToken = null;
let lastSidebarTop = 0;
let lastSidebarleft = 0;

export default function LoggedLayout(props) {
	const navbarRef = useRef(null);
	const configurationDropdownMenuRef = useRef(null);
	const [sidebarTop, setSidebarTop] = useState(lastSidebarTop);
	const [sidebarLeft, setSidebarLeft] = useState(lastSidebarleft);

    const dispatch = useStore(state => state.dispatch);
	const sidebarVisible = useStore(state => state.sidebarVisible);    
    const debug = useStore(state => state.debug);
    const config = useStore(state => state.config);
	const business = getBusiness();  

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	// Reposition sidebar
	useEffect(() => {
		// Top
		let newSidebarTop = navbarRef.current.getBoundingClientRect().height;
		// if ( lastSidebarTop === newSidebarTop ) return;
		lastSidebarTop = newSidebarTop;
		setSidebarTop(newSidebarTop);

		// Left
		let setSidebarLeftInterval = setInterval(() => {
			let newSidebarLeft = navbarRef.current.getBoundingClientRect().left;
			lastSidebarleft = newSidebarLeft;
			setSidebarLeft(newSidebarLeft);
		}, 500);
		
		return function cleanup() {
			clearInterval(setSidebarLeftInterval);
		}
	}, [sidebarVisible]);

    const setSidebarVisible = (status) => {
	    dispatch({
			type: 'setSidebarVisible',
			status: status
		});
    }

    const loadBusinesses = async (input, callback) => {
		let businesses = await BusinessesService.list({
			search: input,
			no_paginate: true
		});

		let formatted = businesses?.map((el) => {
			return {
				value: el, 
				label: (
					<div className="d-flex align-items-center">
						<span className="badge bg-secondary text-white me-1">{el.name_short}</span>
						{el.name}
					</div>
				)
			};
		});
		callback(formatted);
    }

    const selectBusiness = (business) => {
    	setBusiness(business, true);
    }

    const openPopupWindowCallback = (e, name) => {
    	e.preventDefault();

    	openPopupWindow(e.currentTarget.href, name);
    }

	const setConfigurationDropdownMenuHeight = () => {
		configurationDropdownMenuRef.current.style.height = 'auto'; // Reset height

		let boundingClientRect = configurationDropdownMenuRef.current.getBoundingClientRect();
		let windowHeight = window.innerHeight;

		configurationDropdownMenuRef.current.style.overflowY = 'auto';
		configurationDropdownMenuRef.current.style.overflowX = 'hidden';
		
		if (windowHeight < boundingClientRect.bottom) {
			let maxHeight = windowHeight - boundingClientRect.top - 20;
			configurationDropdownMenuRef.current.style.height = maxHeight + 'px';
		}
	}

	return (
		<Layout id="layout">
			<Navbar ref={navbarRef} className="navbar navbar-expand-lg navbar-light fixed-top">
				<div className="container-fluid px-lg-0">
					<div className="navbar-brand">
						{ getBusiness()?.logo_base64_url &&
							<img id="logo" src={getBusiness()?.logo_base64_url} alt="company-logo" />
						}
						{ debug && 
							<span className="badge bg-primary text-white ms-2">debug</span>
						}
						<button className="btn btn-navbar-toggler desktop" tabIndex="-1" onClick={() => setSidebarVisible(!sidebarVisible)}>
							<span className="navbar-toggler-icon"></span>
						</button>
					</div>
					<button className="btn btn-navbar-toggler mobile" tabIndex="-1" onClick={() => setSidebarVisible(!sidebarVisible)}>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className={'collapse navbar-collapse ' + (sidebarVisible ? 'show' : '')}>
						<div className="business_selector_wrapper">
							<EmpoweredSelector
								tabIndex="-1"
								load={(input, callback) => loadBusinesses(input, callback)}
								onChange={(value) => selectBusiness(value)}
								timeout={250}
								label={
									(business && business.id) ? 
										<div className="business_selected_label">
											{/* <span className="name_short badge bg-secondary text-light me-1">{business.name_short}</span> */}
											<span className="name">{business.name}</span>
										</div>
									: 
										null
								}
								placeholder="Seleccionar empresa"
								value={business?.id ?? null}
							/>
							{ (business && business.id) && 
								<NavLink className="btn btn-sm" to={'/businesses/edit/' + business.id}><i className="bi bi-gear"></i></NavLink>
							}
						</div>
						
						<div className="d-flex ms-2 justify-content-center py-2 py-lg-0">
							<NavLink to="/documents" className="btn btn-sm btn-outline-primary ms-2" onClick={(e) => openPopupWindowCallback(e, 'documents')}><i className="bi bi-folder"></i> Documentos</NavLink>

							{/* <NavLink to="/search" className={'btn btn-sm btn-outline-primary ms-2 d-inline-flex align-items-center'} onClick={(e) => openPopupWindowCallback(e, 'search')}><i className="bi bi-search"></i></NavLink> */}
						</div>
						<div className="d-flex ms-auto">

							<ul className="navbar-nav mb-2 mb-lg-0">
								<li className="nav-item">
									<ConfigurationDropdownWrapper className="dropdown">
										<a href="/" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" onClick={setConfigurationDropdownMenuHeight}>
											<DropdownTitle>
												<i className="bi bi-gear bi-big"></i> Configuración
											</DropdownTitle>
										</a>
										<ul className="dropdown-menu dropdown-menu-end" ref={configurationDropdownMenuRef}>
											<li>
												<div className="row mx-0 py-1">
													<UserTitle>
														{ getUser()?.name }
														<NavLink to="/logout"><i className="bi bi-power"></i></NavLink> 
													</UserTitle>
													<div className="col-md-6">
														<LabeledFrame label="Empresa" background={!business?.id ? 'gray-300' : 'primary'} color={!business?.id ? 'gray-500' : 'white'}>
															<NavLink className={'dropdown-item ' + (!business?.id ? 'disabled' : '')} to={'/businesses/edit/' + business?.id}>Ficha</NavLink>
															<hr className="dropdown-divider" />
															<NavLink className={'dropdown-item ' + (!business?.id ? 'disabled' : '')} to="/invoices-series">Series de facturas</NavLink>
															<NavLink className={'dropdown-item ' + (!business?.id ? 'disabled' : '')} to="/payment-methods">Métodos de pago</NavLink>
															<hr className="dropdown-divider" />
															<NavLink className={'dropdown-item ' + (!business?.id ? 'disabled' : '')} to="/trade-categories/incomes">Categorías de ingresos</NavLink>
															<NavLink className={'dropdown-item ' + (!business?.id ? 'disabled' : '')} to="/trade-categories/expenses">Categorías de gastos</NavLink>
														</LabeledFrame>
													</div>
													<div className="col-md-6">
														<LabeledFrame label="General" background={!checkRole('admin') ? 'gray-300' : 'primary'} color={!checkRole('admin') ? 'gray-500' : 'white'}>
															<NavLink className={'dropdown-item ' + (!checkRole('admin') ? 'disabled' : '')} to="/users">Usuarios</NavLink>
															<NavLink className={'dropdown-item ' + (!checkRole('admin') ? 'disabled' : '')} to="/businesses">Empresas</NavLink>
															<hr className="dropdown-divider" />
															<NavLink className={'dropdown-item ' + (!checkRole('admin') ? 'disabled' : '')} to="/taxes">Impuestos</NavLink>
														</LabeledFrame>
													</div>
												</div>
											</li>
										</ul>
									</ConfigurationDropdownWrapper>
								</li>
							</ul>
							
						</div>
					</div>
				</div>
			</Navbar>

			<Sidebar top={sidebarTop} left={sidebarLeft} />

			<Main className={(sidebarVisible ? 'sidebar-visible' : '') + ' ' + (isTouchEnabled() ? 'is-mobile' : '')}>
				{ props.children }
			</Main>
		</Layout>
	);
}