import axios from 'axios';

export default class Auth {

	static axiosCancelToken = null;

	static login = async (user, pass) => {
		this.newAxiosCancelToken();

		return axios.post('/api/login', {
		    username: user,
		    password: pass
	  	}, {
	  		cancelToken: this.axiosCancelToken.token
	  	}).then((response) => {
	    	return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return null;
		});
	}

	static logout = async () => {
		this.newAxiosCancelToken();

		return axios.post('/api/logout', {}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
	    	return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return null;
		});
	}

	static getAuthData = async () => {
		this.newAxiosCancelToken();

		return axios.get('/api/get-authuser-data', {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
	    	return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return null;
		});	
	}

	static requestNewPassword = async (email) => {
		this.newAxiosCancelToken();

		return axios.post('/api/request-new-password', {
			email: email
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
	    	return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return null;
		});
	}

	static sendNewPassword = async (token, email, password) => {
		this.newAxiosCancelToken();

		return axios.post('/api/reset-password', {
			token: token,
			email: email,
			password: password
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
	    	return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return error.response.data;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}