import axios from 'axios';
import { getBusiness } from 'helpers/business';

export default class ProviderInvoices {

	static axiosCancelToken = null;

	static list = (params, business_id = null) => {
		this.newAxiosCancelToken();

		if ( !params || !params.business_id ) {
			if ( !params ) params = {};
			params.business_id = business_id ?? getBusiness()?.id;
		}

		return axios.get('api/providers-invoices/list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static get = (id, params = {}, business_id = null) => {
		this.newAxiosCancelToken();

		if ( !params.business_id ) {
			params.business_id = business_id ?? getBusiness()?.id;
		}

		return axios.get('api/providers-invoices/get/' + id, {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}
	
	static save = (data, business_id = null) => {
		this.newAxiosCancelToken();

		if ( !data || !data.business_id ) {
			if ( !data ) data = {};
			data.business_id = business_id ?? getBusiness()?.id;
		}

		let formData = new FormData();
		for(let i in data) {
			if ( data[i] === undefined || data[i] === null ) continue;
			formData.append(i, data[i]);
		}
		formData.set('items', JSON.stringify(data.items));
		formData.set('counterparts', JSON.stringify(data.counterparts));

		return axios.post('api/providers-invoices/' + (data.id ? 'edit/' + data.id : 'add'), formData, {
			headers: {
				'Content-Type': 'multipart/form-data' 
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data.errors ) return error.response.data;
		});	
	}

	static delete = (id, business_id = null) => {
		this.newAxiosCancelToken();

		return axios.delete('api/providers-invoices/delete/' + id + '?business_id=' + (business_id ?? getBusiness()?.id), {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
	    	return true;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return false;
		});	
	}

	static uploadBulkFiles = (files, progressCallback, business_id) => {
		this.newAxiosCancelToken();

		let formData = new FormData();
		files.forEach(file => {
			formData.append('files[]', file);
		});
		formData.set('business_id', business_id ?? getBusiness()?.id);

		return axios.post('/api/providers-invoices/upload-bulk-files', formData, {
			headers: {
				'Content-Type': 'multipart/form-data' 
			},
			cancelToken: this.axiosCancelToken.token,
			onUploadProgress: (e) => {
				if ( progressCallback ) {
					let percentCompleted = Math.round((e.loaded * 100) / e.total);
					progressCallback(percentCompleted);
				}
			}
		}).then((response) => {
			return true;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return false;
		});
	}

	static listDraft = (params, business_id = null) => {
		this.newAxiosCancelToken();

		if ( !params || !params.business_id ) {
			if ( !params ) params = {};
			params.business_id = business_id ?? getBusiness()?.id;
		}

		return axios.get('api/providers-invoices/list-draft', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}