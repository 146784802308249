import React from 'react';

export default function Details(props) {
	let data = props.data;

	return (
		<div className="row ms-0 me-0">
            <div className="col-md-4">
                <div><b className="sbold">Nombre</b>: {data.name ?? '-'}</div>
                <div><b className="sbold">Razón social</b>: {data.business_name ?? '-'}</div>
                <div><b className="sbold">CIF</b>: {data.vatnumber ?? '-'}</div>
                <div><b className="sbold">Dirección</b>: {data.address ?? '-'}</div>
                <div><b className="sbold">Código postal</b>: {data.postalcode ?? '-'}</div>
                <div><b className="sbold">Ciudad</b>: {data.city ?? '-'}</div>
                <div><b className="sbold">Provincia</b>: {data.province ?? '-'}</div>
                <div><b className="sbold">País</b>: {data.country ?? '-'}</div>
            </div>
            <div className="col-md-8">

            </div>
        </div>
	);
}


