import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import EmpoweredSelector from 'components/EmpoweredSelector';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);
`;

let axiosCancelToken = null;

export default function FolderModal(props) {
	const modalRef = useRef(null);
	const nameRef = useRef(null);

	const [name, setName] = useState(props.folder?.name);
	const [business, setBusiness] = useState({
		id: props.folder.business ? props.folder.business.id : props.business?.id,
		name: props.folder.business ? props.folder.business.name : props.business?.name,
	});
	const [errors, setErrors] = useState({});

	const closeCallback = props.closeCallback;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();
		}

		const shownEvent = (e) => {
       		nameRef.current.focus();
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);
		modal._element.addEventListener('shown.bs.modal', shownEvent);

		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	const loadBusinesses = (input, callback) => {
		axios.get('/api/businesses/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = () => {
		setErrors({});

		axios.post('/api/documents/save-folder' + (props.folder.id && props.folder.id !== -1 ? '/' + props.folder.id : ''), {
			name: name,
			business_id: business?.id,
			parent_id: props.parentFolder && props.parentFolder.id !== -1 ? props.parentFolder.id : null
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			if ( response.data.status ) {
				toast.success('Datos guardados');
				props.closeCallback();
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{props.folder?.id > 0 ? 'Preferencias de la carpeta' : 'Crear carpeta'}</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="mb-2">
							<label>Nombre</label>
							<input type="text" ref={nameRef} className="form-control form-control-sm" value={name ?? ''} onChange={(e) => setName(e.target.value)} />
							{ errors.name &&
								<div className="invalid-feedback d-block">{ errors.name[0] }</div>
							}
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-sm btn-primary text-white" onClick={() => saveData()}>Guardar</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}


