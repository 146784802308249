import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { authUserPermission } from 'helpers/business';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import Family from './Family';
import FieldSmallComment from 'components/FieldSmallComment';
import { default as PrevisionsService } from 'services/Previsions';

const StyledPrevisionsForm = styled.div`

	
`;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function PrevisionsForm() {
	const actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();
	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);
	
	// eslint-disable-next-line no-unused-vars
	let [months, setMonths] = useState([...Array.from({length: 12}, (_, i) => i + 1)]); 

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				let prevision = await PrevisionsService.get(params.id);
				if (prevision) {
					setData({...prevision});
					setCanEdit(false);
				} else {
					setData((prev) => ({...prev, id: null}));
				}

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	useEffect(() => {
		// Sync scrolls
		document.querySelectorAll('.table-wrapper').forEach(element => {
			if ( !element.dataset.syncedScroll ) {
				element.addEventListener('scroll', (e) => {
					document.querySelectorAll('.table-wrapper:not(#' + element.id + ')').forEach(element2 => {
						if ( element2.id !== element.id ) {	
							element2.scrollTop = element.scrollTop;
							element2.scrollLeft = element.scrollLeft;
						}
					});
				});
	
				element.dataset.syncedScroll = true;
			}
		});

		// Set scroll to last added family from the firstone
		if ( data.families && data.families.length > 1 ) {
			let elements = document.querySelectorAll('.table-wrapper');

			let first = elements.item(0);
			let last = elements.item(elements.length - 1);

			last.scrollTop = first.scrollTop;
			last.scrollLeft = first.scrollLeft;
		}
	}, [data]);

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		let result = null;
		try {
			result = await PrevisionsService.save(data);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			setData({...result.prevision});

			if ( popup && goBack ) {
				if ( parentWindowProxyCallback ) data = result.prevision; // Fix because parent needs id and update state function on previous line from this line update state async
				window.close();
				return;
			}

			if ( goBack ) navigate('/previsions');
			else {
		    	navigate('/previsions/edit/' + result.prevision.id + (popup ? '?popup=true' : ''));
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const setDataField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	}

	const addFamily = () => {
		setData((prev) => ({
			...prev,
			families: [
				...(prev.families ?? []),
				{
					id: null,
					name: '',
					order: (prev.families?.length ?? 0) + 1,
					rows: []
				}
			]
		}));
	}

	const removeFamily = (idx) => {
		if ( !window.confirm('¿Estás seguro de eliminar esta familia?') ) return;

		setData((prev) => ({
			...prev,
			families: prev.families.filter((f, i) => i !== idx)
		}));
	}

	const setFamilyData = (idx, familyData) => {
		let newData = {...data};
		newData.families[idx] = familyData;
		setData(newData);
	}

	const deletePrevision = async () => {
		if ( !window.confirm('¿Estás seguro de eliminar esta previsión?') ) return;

		setLoading(true);

		let result = await PrevisionsService.delete(data.id);

		setLoading(false);

		if ( result ) {
			toast.success('Previsión eliminada');
			navigate('/previsions');
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}
	
	const isValidYear = data?.year && !isNaN(parseInt(data?.year));

	const canSave = data?.families?.length > 0;

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<StyledPrevisionsForm>
				<div className="page-title">
					<h1>Ficha de previsión</h1>
					<button onClick={() => popup ? window.close() : navigate('/previsions')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className={'row ' + (popup ? 'justify-content-center mt-3' : '')}>
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-12">
											<div className="row">
												<div className="col-md-3 mb-3">
													<CustomInput label="Nombre" type="text" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} readOnly={!canEdit} />
													{ errors.name &&
														<div className="invalid-feedback d-block">{ errors.name[0] }</div>
													}
												</div>
												<div className="col-md-1 mb-3">
													<CustomInput label="Año" type="number" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('year', e.target.value)} value={data.year ?? ''} readOnly={!canEdit} />
													{ errors.year &&
														<div className="invalid-feedback d-block">{ errors.year[0] }</div>
													}
												</div>
												<div className="col-md-5"></div>
												<div className="col-md-3 text-end mb-3">
													{canEdit && 
														<>
															<button className="btn btn-sm btn-light" onClick={addFamily} disabled={!isValidYear}>Añadir familia</button>
															{ !isValidYear &&
																<FieldSmallComment className="text-danger">Introduce el año para poder añadir familias</FieldSmallComment>
															}
														</>
													}
												</div>
											</div>
										</div>
										
										<div className="col-md-12">
											{ data.families && data.families.map((family, idx) => {
												return (
													<Family
														key={'family' + idx} 
														previsionId={data.id ?? null}
														data={family}
														setData={(data) => setFamilyData(idx, data)}
														remove={() => removeFamily(idx)}
														canEdit={canEdit && isValidYear}
														months={months}
														errors={(() => {
															let filteredErrors = {};

															let keys = Object.keys(errors ?? {}).filter(k => k.indexOf('families.' + idx + '.') !== -1);

															for(let k of keys) {
																filteredErrors[k.replace('families.' + idx + '.', '')] = errors[k];
															}
															
															return filteredErrors;
														})()}
													/>
												);
											})}
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-4">
											{ ( data.id && authUserPermission('delete') ) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deletePrevision()}>Eliminar</button>							
											}
										</div>
										<div className="col-8 text-end">
											{ ((data.id && authUserPermission('edit')) || authUserPermission('add') ) &&
												<>
													<div className="dropdown d-inline">
														<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
															Acciones
														</button>
														<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
															<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
															<li><hr className="dropdown-divider" /></li>

															<li><button className="dropdown-item" onClick={() => saveData(false)} disabled={!canSave}>Guardar</button></li>						
															<li><button className="dropdown-item" onClick={() => saveData()} disabled={!canSave}>Guardar y salir</button></li>
														</ActionsContextMenu>
													</div>

													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)} disabled={!canSave}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()} disabled={!canSave}>Guardar y salir</button>					
												</>
											}		
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</StyledPrevisionsForm>
		</Layout>
	);
}