import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { authUserPermission } from "helpers/business";
import BulkCopyModal from "./BulkCopyModal";
import GenerateBankRemittanceModal from "./GenerateBankRemittanceModal";
import { default as  ReceiptsService } from "services/Receipts";
import AssociateBankRemittanceModal from "./AssociateBankRemittanceModal";

export default function BulkActionsDropdown(props) {
    const navigate = useNavigate();

    const parentForceReload = props.parentForceReload;
    const selectedItems = props.selectedItems;

	const [modalBulkCopyOpened, setModalBulkCopyOpened] = useState(false);
	const [modalBankRemittanceOpened, setModalBankRemittanceOpened] = useState(false);
	const [modalExistingBankRemittanceOpened, setModalExistingBankRemittanceOpened] = useState(false);

    const copyBulk = async (due_date, generateInvoices = false) => {
		for await (let item of selectedItems) {
            let receipt = {...item};

			// Set new date
			receipt.id = null;
			receipt.due_date = due_date;
            receipt.invoice_id = null; // Prevent associate same invoice

            if ( generateInvoices && item.invoice_id ) receipt.generate_invoice_from_id = item.invoice_id;
			
			await ReceiptsService.save(receipt);
		}

		toast.info('Recibos copiados');
        parentForceReload();
	}

	const deleteBulk = async () => {
		const c = window.confirm('¿Seguro que quieres eliminar los recibos seleccionados?');
		if ( !c ) return;

		for await (let item of selectedItems) {
            await ReceiptsService.delete(item.id);
		}

		toast.info('Recibos borrados');
        parentForceReload();
	}

    let canGenerateRemittance = selectedItems.filter(el => el.remittance_id || el.amount <= 0).length > 0 ? false : true

	return (
        <>
            <div className="dropdown">
                <button className="btn btn-light dropdown-toggle py-1" type="button" data-bs-toggle="dropdown" onClick={(e) => e.stopPropagation()}>
                    Acciones 
                </button>
                <ul className="dropdown-menu dropdown-tablerow-actions">
                    {authUserPermission('add') &&
                        <>
                            {canGenerateRemittance &&
                                <>
                                    { authUserPermission('add') &&
                                        <li>
                                            <button className="dropdown-item" onClick={() => setModalBankRemittanceOpened(true)}>
                                                <i className="bi bi-bank"></i>&nbsp;
                                                Generar remesa bancaria
                                            </button>
                                        </li>
                                    }
                                    { authUserPermission('add') &&
                                        <li>
                                            <button className="dropdown-item" onClick={() => setModalExistingBankRemittanceOpened(true)}>
                                                <i className="bi bi-bank"></i>&nbsp;
                                                Asociar a remesa bancaria existente
                                            </button>
                                        </li>
                                    }
                                    <li><hr className="dropdown-divider" /></li>
                                </>
                            }
                            
                            <li>
                                <button className="dropdown-item" onClick={() => setModalBulkCopyOpened(true)}>
                                    <i className="bi bi-copy"></i>&nbsp;
                                    Copiar
                                </button>
                            </li>
                        </>
                    }
                    { (authUserPermission('delete') && canGenerateRemittance) &&
                        <li>
                            <button className="dropdown-item" onClick={deleteBulk}>
                                <i className="bi bi-x"></i>&nbsp;
                                Eliminar
                            </button>
                        </li>
                    }
                </ul>
            </div>
            { modalBulkCopyOpened &&
                <BulkCopyModal
                    selectedItems={selectedItems}
                    closeCallback={(props) => {
                        if ( props?.date ) copyBulk(props.date, props.generateInvoices ?? false);

                        setModalBulkCopyOpened(false);
                    }}
                />
            }
            { modalBankRemittanceOpened &&
                <GenerateBankRemittanceModal
                    selectedItems={selectedItems}
                    closeCallback={(redirect) => {
                        if ( redirect ) navigate('/bankremittances');

                        setModalBankRemittanceOpened(false);
                    }}
                />
            }
            { modalExistingBankRemittanceOpened &&
                <AssociateBankRemittanceModal
                    selectedItems={selectedItems}
                    closeCallback={(update) => {
                        if ( update ) parentForceReload();

                        setModalExistingBankRemittanceOpened(false);
                    }}
                />
            }
        </>
	);
}