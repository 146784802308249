import React, { useState } from "react";
import { toast } from "react-toastify";
import JSZip, { file } from "jszip";
import { authUserPermission } from "helpers/business";
import { default as ProviderInvoicesService } from "services/ProviderInvoices";
import { default as FilesService } from "services/Files";
import { CristalLoader, downloadFile } from "helpers/generic";
import moment from "moment";

export default function BulkActionsDropdown(props) {
    const parentForceReload = props.parentForceReload;
    const selectedItems = props.selectedItems;

	const [loading, setLoading] = useState(false);

	const deleteBulk = async () => {
		const c = window.confirm('¿Seguro que quieres eliminar los gastos seleccionados?');
		if ( !c ) return;

		for await (let item of selectedItems) {
            await ProviderInvoicesService.delete(item.id);
		}

		toast.info('Gastos borrados');
        parentForceReload();
	}

    const downloadBulk = async () => {
        setLoading(true);

        if ( selectedItems.length > 1 ) {
            let zip = new JSZip();
            let withErrors = false;

            for await (let item of selectedItems) {
                if ( !item.file ) continue;

                let result = await FilesService.download(item.file.id);
                if ( result ) {
                    let fileName = item.file.name;
                    if ( !fileName.includes('.') ) {
                        fileName += '.' + item.file.extension;
                    }
                    zip.file(fileName, result);
                } else {
                    withErrors = true;
                }
            }
    
            zip.generateAsync({type: 'blob'}).then((content) => {
                downloadFile(content, 'Facturas recibidas ' + moment().format('DD-MM-YYYY') + ' ' +  moment().format('HH:mm:ss') + ' .zip');
            });

            if ( withErrors ) {
                toast.error('Se han descargado los gastos pero algunos contienen errores y no se han añadido al archivo comprimido');
            }
        } else if (selectedItems[0].file) { // Single expense
            let result = await FilesService.download(selectedItems[0].file.id);
            downloadFile(result, selectedItems[0].file.name);
        }

        setLoading(false);
    }

	return (
        <>
            {loading && <CristalLoader />}
            <div className="dropdown">
                <button className="btn btn-light dropdown-toggle py-1" type="button" data-bs-toggle="dropdown" onClick={(e) => e.stopPropagation()}>
                    Acciones 
                </button>
                <ul className="dropdown-menu dropdown-tablerow-actions">
                    <li>
                        <button className="dropdown-item" onClick={() => downloadBulk()}>
                            <i className="bi bi-download"></i>&nbsp;
                            Descargar
                        </button>
                    </li>

                    {authUserPermission('delete') &&
                        <>
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                                <button className="dropdown-item" onClick={deleteBulk}>
                                    <i className="bi bi-x"></i>&nbsp;
                                    Eliminar
                                </button>
                            </li>
                        </>
                    }
                </ul>
            </div>
        </>
	);
}