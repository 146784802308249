import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import PopupLayout from "layouts/PopupLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { getBusiness } from 'helpers/business';
import { formatNumber, openPopupWindow } from 'helpers/generic';
import { default as ProviderInvoicesService } from 'services/ProviderInvoices';

const Table = styled.table`
	tr {

		&.duplicated {
			background-color: rgba(var(--bs-indigo-rgb), 0.4);

			small.duplicated {
				background-color: rgba(var(--bs-indigo-rgb), 0.8);
				color: white;
				border-radius: 5px;
				padding: 0 5px;
				font-weight: bold;
			}
		}
	}

	th,td {
		&:nth-child(3) {
			width: 150px;
		}
	
		&:nth-child(4) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(5) {
			width: 80px;
			text-align: right;
			white-space: nowrap;
		}

		&:nth-child(6) {
			text-align: right;
			white-space: nowrap;
		}

		&:nth-child(7) {
			width: 80px;
			text-align: center;
		}

		&:nth-child(8) {
			width: 40px;
			text-align: center;

			a {
				font-size: 15px;
			}
		}
	}
`;

let searchTimeout = null;

export default function ProvidersInvoicesDraft() {
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const searchQS = queryParams.get('search') ?? undefined;

	let [forceReload, setForceReload] = useState(null);
	let [invoices, setInvoices] = useState({});
	let [search, setSearch] = useState(searchQS);
	let [business, setBusiness] = useState({
		id: getBusiness()?.id,
		name: getBusiness()?.name
	});
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('date');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setInvoices({...invoices, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		const getInvoices = async () => {
			let invoices = await ProviderInvoicesService.listDraft({
				page: 				page,
				search: 			search,
				sort: 				sortField,
				direction: 			sortDirection,			
				pending_review: 	true
            });

			setInvoices({...invoices.invoices});
			setSkeletonRows(invoices.invoices.data.length);
		}

		getInvoices();
	}, [forceReload, page, search, business, sortField, sortDirection]);

	useEffect(() => {
		window.PopupProxyCallback = (newInvoice) => {
            setInvoices({});
			setForceReload(Math.random());
		}

		return function cleanup() {
			window.PopupProxyCallback = null;
		}
	}, []);

	const openPopupWindowFix = (e) => {
		e.preventDefault();

		let href = e.currentTarget.href;
		openPopupWindow(href);
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<section>
				<div className="page-title">
					<h1>Facturas pendientes de revisión</h1>
					<NavLink to={-1} className="btn btn-light btn-sm ms-auto">Volver</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									<input type="text" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} value={searchQS} />
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0  mt-md-0 text-end">

								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'provider.name'} onClick={() => sortTableClick('provider.name')}>Proveedor</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.number'} onClick={() => sortTableClick('providers_invoices.number')}>Nº</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.date'} onClick={() => sortTableClick('providers_invoices.date')}>Fecha</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.base'} onClick={() => sortTableClick('providers_invoices.base')}>Base</ThSortable>
											<th>Impuestos</th>
											<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.total'} onClick={() => sortTableClick('providers_invoices.total')}>Total</ThSortable>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ invoices.data?.length > 0 &&
											invoices.data.map((el, idx) => {
												return ( 
													<React.Fragment key={'p'+idx}>
														<tr className={(el.draft_duplicated ? 'duplicated' : '')}>
															<td>
																{el.provider?.name ?? '-'}
																<div><small>{el.provider?.vatnumber ?? '-'}</small></div>
																{el.draft_duplicated &&
																	<small className="duplicated">FACTURA REPETIDA</small>
																}
															</td>
															<td>{ el.number ?? '-' }</td>
															<td>{ el.date ? moment(el.date).format('DD-MM-YYYY') : '-' }</td>
															<td>{ formatNumber(el.base ?? 0) } €</td>
															<td>
																{el.taxes?.map((iEl, iIdx) => {
																	return <div key={iIdx}>{iEl.name}: {formatNumber(iEl.amount, 2)} €</div>
																})}
															</td>
															<td>{ formatNumber(el.total ?? 0) } €</td>
															<td>
																<NavLink className="btn-table" onClick={openPopupWindowFix} to={'/providers-invoices/edit/' + el.id + '?popup=true&draft=true'}><i className="bi bi-clipboard-check"></i></NavLink>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ invoices.data && !invoices.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ invoices.data === undefined && <TrSkeleton rows={skeletonRows} columns={7} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={invoices?.current_page}
									max={invoices?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}


