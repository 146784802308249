const setToSession = (key, value) => {
    let toSave = value;
    if ( toSave instanceof Object ) toSave = JSON.stringify(value);
    sessionStorage.setItem(key, toSave);
};

const getFromSession = (key) => {
    let data = sessionStorage.getItem(key);
    
    try {
        data = JSON.parse(data);
    } catch (e) {}

    return data;
}

const removeFromSession = (key) => {
    sessionStorage.removeItem(key);
}

export { setToSession, getFromSession, removeFromSession };