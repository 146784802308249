import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { Tooltip } from 'bootstrap/dist/js/bootstrap';
import { default as UsersService } from 'services/Users';


const Table = styled.table`
	th,td {
		@media (min-width: 768px) {
			&:nth-child(3),
			&:nth-child(4) {
				width: 150px;
			}
		
			&:nth-child(2),
			&:nth-child(5) {
				width: 150px;
			}

			&:nth-child(6) {
				width: 40px;
			}
		}
		
		@media (max-width: 768px) {
			&:nth-child(2),
			&:nth-child(4),
			&:nth-child(5) {
				display: none;
			}
		}
	}

	.principal-asterisk {
		font-size: 12px;
		color: var(--bs-primary);
	}
`;

let searchTimeout = null;

export default function Companies() {
	const [queryParams] = useSearchParams();

	let tableBodyRef = useRef(null);

	let [users, setUsers] = useState({});
	let [search, setSearch] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('name');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setUsers({...users, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	useEffect(() => {
		const getUsers = async () => {
			let users = await UsersService.list({
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
			});

			setUsers({...users});
			setSkeletonRows(users?.data?.length);
		}

		getUsers();
	}, [page, search, sortField, sortDirection]);


	useEffect(() => {
		// Tooltips
		let tooltipsToggles = tableBodyRef.current.querySelectorAll('[data-bs-toggle="tooltip"]');
		let tooltipsList = [];
		if ( tooltipsToggles.length ) { 
			tooltipsToggles.forEach((el) => {
				tooltipsList.push(new Tooltip(el));
			});
		}
	}, [users]);

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Usuarios</h1>

					<NavLink to="/users/add" className="btn btn-sm btn-light ms-auto">Nuevo usuario</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'vatnumber'} onClick={() => sortTableClick('vatnumber')}>CIF</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'phone'} onClick={() => sortTableClick('phone')}>Teléfono</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'email'} onClick={() => sortTableClick('email')}>Email</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'created_at'} onClick={() => sortTableClick('created_at')}>Creado el</ThSortable>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody ref={tableBodyRef}>
										{ users.data?.length > 0 &&
											users.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>
																{ el.role === 'admin' &&
																	<span className="principal-asterisk">
																		<i className="bi bi-asterisk" data-bs-toggle="tooltip" title="Administrador"></i>
																		&nbsp;&nbsp;
																	</span>
																}

																{el.name}
															</td>
															<td>{el.vatnumber}</td>
															<td>{el.phone}</td>
															<td>{el.email}</td>
															<td>
																{ moment(el.created_at).format('DD-MM-YYYY') }
																&nbsp;<small>{ moment(el.created_at).format('HH:mm') }</small>
															</td>
															<td>
																<NavLink className="btn-table" to={'/users/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<div className="col-md-4">
																			<div><b className="sbold">Nombre</b>: {el.name ?? '-'}</div>
																			<div><b className="sbold">CIF</b>: {el.vatnumber ?? '-'}</div>
																			<div><b className="sbold">Dirección</b>: {el.address ?? '-'}</div>
																			<div><b className="sbold">Código postal</b>: {el.postalcode ?? '-'}</div>
																			<div><b className="sbold">Ciudad</b>: {el.city ?? '-'}</div>
																			<div><b className="sbold">Provincia</b>: {el.province ?? '-'}</div>
																			<div><b className="sbold">País</b>: {el.country ?? '-'}</div>
																		</div>
																		<div className="col-md-8">
																			{ el.role === 'admin' &&
																				<div>
																					<span className="principal-asterisk">
																						<i className="bi bi-asterisk" data-bs-toggle="tooltip" title="Administrador"></i>
																						&nbsp;&nbsp;
																					</span>

																					Este usuario es administrador
																				</div>
																			}
																		</div>
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ users.data && !users.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ users.data === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={users?.current_page}
									max={users?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


