import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import LoggedLayout from 'layouts/LoggedLayout';
import { CristalLoader } from 'helpers/generic';
import { getBusiness, authUserPermission } from 'helpers/business';
import FieldSmallComment from 'components/FieldSmallComment';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import { default as InvoicesSeriesService } from 'services/InvoicesSeries';

export default function InvoicesSeriesForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();

	let [data, setData] = useState({
		id: undefined, // Fix to prevent get preview full number if editing existing serie
		prefix: '',
		filling: '',
		default: false
	});
	let [preview, setPreview] = useState('');
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);

	let isUsed = data.is_used ?? false;

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				let serie = await InvoicesSeriesService.get(params.id);
				if (serie) {
					setData({...serie});
					setPreview(serie.preview);
					setCanEdit(false);
				} else {
					setData((prev) => ({...prev, id: null}));
				}

				setLoading(false);
			}
			getData();
		} else {
			setData((prev) => ({...prev, id: null})); // Fix when adding to show preview full number

			const getLastOrder = async () => {
				setLoading(true);

				let lastOrder = await InvoicesSeriesService.getLastOrder(getBusiness()?.id);
				if (lastOrder) setData(data => ({...data, order: lastOrder.order}));

				setLoading(false);
			}
			getLastOrder();
		}
	}, [params.id]);

	useEffect(() => {
		if ( data.id || data.id === undefined ) return;

		let getPreview = async () => {
			let preview = await InvoicesSeriesService.getPreviewInvoiceFullNumber(data.prefix, data.filling, moment().format('YYYY-MM-DD'), 1, getBusiness()?.id);
			setPreview(preview);
		}
		getPreview();
	}, [data.id, data.prefix, data.filling])

	const deleteSerie = async () => {
		const c = window.confirm('¿Quieres ' + (data.is_used ? 'archivar' : 'eliminar') + ' esta serie?');
		if ( !c ) return;

		let result = await InvoicesSeriesService.delete(data.id);
		if ( result ) {
			toast.success('Serie borrada')
	    	navigate('/invoices-series');
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}
	const undeleteSerie = async () => {
		const c = window.confirm('¿Quieres desarchivar esta serie?');
		if ( !c ) return;

		let result = await InvoicesSeriesService.undelete(data.id);
		if ( result ) {
			toast.success('Serie desarchivada')
	    	navigate('/invoices-series');
		} else {
			toast.error('Ha ocurrido un error al desarchivar');
		}
	}

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		let result = null;
		try {
			result = await InvoicesSeriesService.save(data, getBusiness()?.id);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			
			if ( goBack ) navigate('/invoices-series');
			else {
		    	navigate('/invoices-series/edit/' + result.serie.id);
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	return (
		<LoggedLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de serie de factura</h1>
					<button onClick={() => navigate('/invoices-series')} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="row">
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} readOnly={!canEdit} />
													{ errors.name &&
														<div className="invalid-feedback d-block">{ errors.name[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput label="Prefijo" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('prefix', e.target.value)} value={data.prefix ?? ''} readOnly={!canEdit || isUsed} />
													<FieldSmallComment className="mt-1">{'{año} {mes} {dia}'}</FieldSmallComment>
													{ errors.prefix &&
														<div className="invalid-feedback d-block">{ errors.prefix[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput label="Relleno" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('filling', e.target.value)} value={data.filling ?? ''} readOnly={!canEdit || isUsed} />
													{ errors.filling &&
														<div className="invalid-feedback d-block">{ errors.filling[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput label="Orden" type="number" className="form-control form-control-sm" onChange={(e) => setDataField('order', e.target.value)} value={data.order ?? ''} readOnly={!canEdit} />
													<FieldSmallComment className="mt-1">Este campo determina el orden en el que apareceran las series en los listados y formularios</FieldSmallComment>
													{ errors.order &&
														<div className="invalid-feedback d-block">{ errors.order[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomSelect label="Por defecto" className="form-control form-control-sm" onChange={(e) => setDataField('default', e.target.value)} value={data.default ?? ''} readOnly={!canEdit}>
														<option value="0">No</option>
														<option value="1">Si</option>
													</CustomSelect>
													<FieldSmallComment className="mt-1">Si se marca por defecto aparecerá como serie preseleccionada en los formularios</FieldSmallComment>
													{ errors.default &&
														<div className="invalid-feedback d-block">{ errors.default[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput label="Vista previa" type="text" className="form-control form-control-sm" value={preview ?? ''} onChange={(e) => null} readOnly />
												</div>
											</div>
										</div>	
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-4">
											{ ( data.id && authUserPermission('delete') ) &&
												<React.Fragment>
													{data.deleted_at ?
														<button className="btn btn-sm btn-link text-success" tabIndex="-1" onClick={() => undeleteSerie()}>Desarchivar</button>
														:
														<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteSerie()}>{data.is_used ? 'Archivar' : 'Eliminar'}</button>							
													}
												</React.Fragment>
											}
										</div>
										<div className="col-8 text-end">
											{ (data.id && authUserPermission('edit') || authUserPermission('add') ) &&
												<>
													<div className="dropdown d-inline">
														<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
															Acciones
														</button>
														<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
															<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
															<li><hr className="dropdown-divider" /></li>

															<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
															<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
														</ActionsContextMenu>
													</div>

													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>					
												</>
											}		
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}