import React, { useState } from "react";
import { toast } from "react-toastify";
import JSZip from "jszip";
import { authUserPermission } from "helpers/business";
import BulkCopyModal from "./BulkCopyModal";
import BulkGenerateReceiptsModal from "./BulkGenerateReceiptsModal";
import { default as  InvoicesService } from "services/Invoices";
import { CristalLoader, downloadFile } from "helpers/generic";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function BulkActionsDropdown(props) {
    const navigate = useNavigate();

    const parentForceReload = props.parentForceReload;
    const selectedItems = props.selectedItems;

	const [modalBulkCopyOpened, setModalBulkCopyOpened] = useState(false);
	const [modalBulkGenerateReceiptsOpened, setModalBulkGenerateReceiptsOpened] = useState(false);
	const [loading, setLoading] = useState(false);

	const deleteBulk = async () => {
		const c = window.confirm('¿Seguro que quieres eliminar las facturas seleccionadas?');
		if ( !c ) return;

		for await (let item of selectedItems) {
            await InvoicesService.delete(item.id);
		}

		toast.info('Facturas borradas');
        parentForceReload();
	}

    const downloadBulk = async () => {
        setLoading(true);

        if ( selectedItems.length > 1 ) {
            let zip = new JSZip();
            let withErrors = false;

            for await (let item of selectedItems) {
                let result = await InvoicesService.getPdf(item.id);
                if ( result ) {
                    zip.file('Factura ' + item.number_full + '.pdf', result);
                } else {
                    withErrors = true;
                }
            }
    
            zip.generateAsync({type: 'blob'}).then((content) => {
                downloadFile(content, 'Facturas ' + moment().format('DD-MM-YYYY') + ' ' +  moment().format('HH:mm:ss') + ' .zip');
            });

            if ( withErrors ) {
                toast.error('Se han descargado las facturas pero algunas contienen errores y no se han añadido al archivo comprimido');
            }
        } else { // Single invoice
            let result = await InvoicesService.getPdf(selectedItems[0].id);
            downloadFile(result, 'Factura ' + selectedItems[0].number_full + '.pdf');
        }

        setLoading(false);
    }

	return (
        <>
            {loading && <CristalLoader />}
            <div className="dropdown">
                <button className="btn btn-light dropdown-toggle py-1" type="button" data-bs-toggle="dropdown" onClick={(e) => e.stopPropagation()}>
                    Acciones 
                </button>
                <ul className="dropdown-menu dropdown-tablerow-actions">
                    <li>
                        <button className="dropdown-item" onClick={() => downloadBulk()}>
                            <i className="bi bi-download"></i>&nbsp;
                            Descargar
                        </button>
                    </li>

                    {authUserPermission('add') &&
                        <>
						    <li><hr className="dropdown-divider" /></li>
                            <li>
                                <button className="dropdown-item" onClick={() => setModalBulkGenerateReceiptsOpened(true)}>
                                    <i className="bi bi-map"></i>&nbsp;
                                    Generar recibos
                                </button>
                            </li>
                        </>
                    }

                    {(authUserPermission('add') || authUserPermission('delete')) &&
						<li><hr className="dropdown-divider" /></li>
                    }

                    {authUserPermission('add') &&
                        <li>
                            <button className="dropdown-item" onClick={() => setModalBulkCopyOpened(true)}>
                                <i className="bi bi-copy"></i>&nbsp;
                                Copiar
                            </button>
                        </li>
                    }
                    {authUserPermission('delete') &&
                        <li>
                            <button className="dropdown-item" onClick={deleteBulk}>
                                <i className="bi bi-x"></i>&nbsp;
                                Eliminar
                            </button>
                        </li>
                    }
                    
                </ul>
            </div>
            { modalBulkCopyOpened &&
                <BulkCopyModal
                    selectedItems={selectedItems}
                    closeCallback={() => {
                        parentForceReload();
                        setModalBulkCopyOpened(false);
                    }}
                />
            }
            { modalBulkGenerateReceiptsOpened &&
                <BulkGenerateReceiptsModal
                    selectedItems={selectedItems}
                    closeCallback={(redirect) => {
                        if ( redirect ) navigate('/receipts');
                        setModalBulkGenerateReceiptsOpened(false);
                    }}
                />
            }
        </>
	);
}