import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import moment from "moment";
import { toast } from "react-toastify";
import CustomInput from 'components/CustomInput';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as TreasuriesService } from "services/Treasuries";
import { default as BankRemittancesService } from "services/BankRemittances";
import { CristalLoader } from "helpers/generic";

const ModalStyled = styled.div`

    & > button {
        padding: 0;
        line-height: 0;
        border: 0;
        background: transparent;
        color: var(--bs-primary);
    }

    .modal {
	    background: rgba(0, 0, 0, 0.4);
    }

`;

export default function CopyBankRemittanceModal(props) {
	const modalRef = useRef(null);

    const remittance = props.remittance;
    const closeCallback = props.closeCallback;

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [paymentDate, setPaymentDate] = useState(moment().format('YYYY-MM-DD'));
    const [treasury, setTreasury] = useState(remittance.treasury_data ?? {});
    const [concept, setConcept] = useState(remittance.concept ?? '');
    const [generateInvoicesFromReceipts, setGenerateInvoicesFromReceipts] = useState(false);
    
    useEffect(() => {
        const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

        modal.show();
    }, []);

    const loadTreasury = async (input, callback) => {
		let treasuries = await TreasuriesService.list({
            type: 'bankaccount',
			search: input,
			no_paginate: true,
		});
		
		let formatted = treasuries?.map((el) => {
			return {
				value: el, 
				label: (
					<div>
						{el.name}
                        <div><small>{el.data?.iban}</small></div>
					</div>
				)
			};
		});
		callback(formatted);
	}

    const closeModal = (redirect = false) => {
        const modal = Modal.getInstance(modalRef.current);
		modal.hide();

        closeCallback(redirect);
    }

    const save = async () => {
        setErrors({});

        setLoading(true);
        let result = await BankRemittancesService.duplicate({
            original_id: remittance.id, 
            date: date,
            payment_date: paymentDate,
            treasury_id: treasury?.id,
            concept: concept,
            generate_invoices_from_receipts: generateInvoicesFromReceipts
        });
        setLoading(false);

        if ( result.status ) {
			toast.info('Remesa generada');
            closeModal(true);
        } else {
            setErrors(result.errors);
			toast.error('Ha ocurrido un error al eliminar');
        }
    }

    const existsReceiptsWithoutInvoices = remittance.receipts.filter(el => !el.invoice_id).length > 0;

	return (
        <ModalStyled>
            {loading && <CristalLoader />}
            <div className="modal" tabIndex="-1" ref={modalRef}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Copiar remesa bancaria</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal()} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <CustomInput label="Fecha de generación" type="date" className="form-control form-control-sm" value={date ?? ''} onChange={(e) => setDate(e.target.value)} readOnly={true} />
                                        { errors['date'] &&
                                            <div className="invalid-feedback d-block">{ errors['date'][0] }</div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <CustomInput label="Fecha de pago" type="date" className="form-control form-control-sm" value={paymentDate ?? ''} onChange={(e) => setPaymentDate(e.target.value)} />
                                        { errors['payment_date'] &&
                                            <div className="invalid-feedback d-block">{ errors['payment_date'][0] }</div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <EmpoweredSelector
                                            load={loadTreasury}
                                            onChange={(value) => setTreasury({id: value?.id, name: value?.name})}
                                            timeout={250}
                                            label={treasury?.name}
                                            placeholder="- Banco -"
                                            value={treasury?.id}
                                        />
                                        { errors['treasury_id'] &&
                                            <div className="invalid-feedback d-block">{ errors['treasury_id'][0] }</div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-0">
                                        <CustomInput label="Concepto" type="text" className="form-control form-control-sm" value={concept ?? ''} onChange={(e) => setConcept(e.target.value)} />
                                        { errors['concept'] &&
                                            <div className="invalid-feedback d-block">{ errors['concept'][0] }</div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-0">
                                        <div className="form-check d-flex align-items-center mb-0">
                                            <input 
                                                className="form-check-input me-2" 
                                                type="checkbox" 
                                                id="generate-invoice" 
                                                checked={generateInvoicesFromReceipts} 
                                                onChange={(e) => setGenerateInvoicesFromReceipts(e.target.checked)} 
                                            />
                                            <label className="form-check-label mt-1" htmlFor="generate-invoice">
                                                Generar facturas de los recibos
                                            </label>
                                        </div>
                                        {(generateInvoicesFromReceipts && existsReceiptsWithoutInvoices) &&
                                            <div className="invalid-feedback d-block">
                                                Algunos recibos seleccionados no tienen facturas asociadas. Solo se generarán nuevas facturas de los recibos que tienen facturas asociadas.
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={'btn btn-sm btn-primary text-white'} onClick={() => save()}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyled>
	);
}