import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { default as ProviderInvoicesService } from 'services/ProviderInvoices';

const DraftStyled = styled.div`
    .alert {
        display: flex;
        flex-direction: row;
        align-items: center;

        i {

            &.icon-big {
                font-size: 24px;
                line-height: 0;
            }
        }
    }
`;

const DraftInvoices = (props) => {
    const business = props.business;
    const forceReload = props.reload;

    const [usingAI, setUsingAI] = useState(false);
    const [processingCount, setProcessingCount] = useState(0);
    const [pendingReviewCount, setPendingReviewCount] = useState(0);

    useEffect(() => {
		const getProcessingCount = async () => {
            let result = await ProviderInvoicesService.listDraft({
                count: true,
                pending_review: false
            });

			setUsingAI(result.using_ai);
            setProcessingCount(result.invoices);
		}
		getProcessingCount();

		const getPendingReviewCount = async () => {
            let result = await ProviderInvoicesService.listDraft({
                count: true,
                pending_review: true
            });

            setPendingReviewCount(result.invoices);
		}
		getPendingReviewCount();

        let getProcessingInterval = setInterval(getProcessingCount, 10000);
        let getPendingReviewInterval = setInterval(getPendingReviewCount, 10000);

        return () => {
            clearInterval(getProcessingInterval);
            clearInterval(getPendingReviewInterval);
        }
	}, [business?.id, forceReload]);

    return (
        <>
            {(pendingReviewCount > 0 || processingCount > 0) &&
                <DraftStyled id="draft-invoices">
                    { processingCount > 0 &&
                        <div className="alert alert-warning" role="alert">
                            <div className="spinner-border me-2" role="status"></div>
                            {usingAI ? 'Se están procesando los archivos subidos con inteligencia artificial' : ''}
                        </div>
                    }

                    { pendingReviewCount > 0 &&
                        <div className="alert alert-danger" role="alert">
                            <i className="bi bi-exclamation-triangle-fill icon-big me-2"></i>
                            {usingAI ? 'Hay facturas pendientes de revisión' : 'Hay facturas pendientes de insertar la información'}
                            <NavLink to="/providers-invoices/draft" className="btn btn-danger btn-sm ms-auto text-white">Ver listado</NavLink>
                        </div>
                    }
                </DraftStyled>
            }
        </>
    )
}

export default DraftInvoices;