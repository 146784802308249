import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { default as CustomFieldsService } from 'services/CustomFields';

// Get proxy callback from parent opener to make updates if possible
let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function CustomFieldsForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const type = params.type;

	let [data, setData] = useState({
		type: type,
		fieldtype: 'text',
		order: 0,
		name: ''
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				let customfield = await CustomFieldsService.get(type, params.id);
				if (customfield) setData({...customfield});

				setLoading(false);
			}
			getData();
		} else {
			const getLastOrder = async () => {
				let lastorder = await CustomFieldsService.getLastOrder(type);
				if (lastorder) {
					setData(data => ({...data, order: lastorder.order}));
				}
			}
			getLastOrder();
		}
	}, [type, params.id]);

	const deleteField = async () => {
		const c = window.confirm('¿Quieres eliminar este campo?');
		if ( !c ) return;

		let result = await CustomFieldsService.delete(type, data.id);
		if ( result ) {
			toast.success('Campo borrado')
			navigate('/custom-fields/' + type);
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}

		if ( parentWindowProxyCallback ) parentWindowProxyCallback();
	}

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		let result = null;
		try {
			result = await CustomFieldsService.save(type, data);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			setData({...result.field});
			
			if ( goBack ) {
				navigate('/custom-fields/' + type);
			} else {
		    	navigate('/custom-fields/'+type+'/edit/' + result.field.id);
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}

		if ( parentWindowProxyCallback ) parentWindowProxyCallback();
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	return (
		<PopupLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de campo personalizado</h1>
					<button onClick={() => navigate('/custom-fields/' + type)} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content mb-4">
					<div className="row">
						<div className="col-lg-4">
							<div className="mb-2">
								<label>Nombre</label>
								<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
								{ errors.name &&
									<div className="invalid-feedback d-block">{ errors.name[0] }</div>
								}
							</div>
						</div>
						<div className="col-lg-4">
							<div className="mb-2">
								<label>Orden</label>
								<input type="number" className="form-control form-control-sm" onChange={(e) => setDataField('order', e.target.value)} value={data.order ?? ''} />
								{ errors.order &&
									<div className="invalid-feedback d-block">{ errors.order[0] }</div>
								}
							</div>
						</div>
						<div className="col-lg-4">
							<div className="mb-2">
								<label>Tipo de campo</label>
								<select className="form-control form-control-sm" onChange={(e) => setDataField('fieldtype', e.target.value)} value={data.fieldtype ?? ''}>
									<option value="text">Texto</option>
									<option value="link">Enlace</option>
								</select>
								{ errors.fieldtype &&
									<div className="invalid-feedback d-block">{ errors.fieldtype[0] }</div>
								}
							</div>
						</div>
					</div>
				</div>

				<footer id="footer-fixed">
					<div className="row h-100 d-flex align-items-center">
						<div className="col-6">
							{ data.id &&
								<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteField()}>Eliminar</button>							
							}
						</div>
						<div className="col-6 text-end py-2 pe-4">
							<React.Fragment>
								<button className="btn btn-sm btn-primary text-white d-inline m-1" onClick={() => saveData(false)}>Guardar</button>							
								<button className="btn btn-sm btn-primary text-white d-inline ms-2 m-1" onClick={() => saveData()}>Guardar y salir</button>
							</React.Fragment>						
						</div>
					</div>
				</footer>
			</section>
		</PopupLayout>
	);
}