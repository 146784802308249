import React, { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useSearchParams, NavLink } from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import { CristalLoader, downloadFile } from 'helpers/generic';
import { getBusiness, authUserPermission } from 'helpers/business';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { openPopupWindow } from 'helpers/generic';
import BusinessPanel from './BusinessPanel';
import ClientPanel from './ClientPanel';
import Items from './Items';
import Payments from './Payments';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import CustomTextarea from 'components/CustomTextarea';
import { default as PaymentMethodsService } from 'services/PaymentMethods';
import { default as TradeCategoriesService } from 'services/TradeCategories';
import { default as InvoicesSeriesService } from 'services/InvoicesSeries';
import { default as InvoicesService } from 'services/Invoices';
import { default as ReceiptsService } from 'services/Receipts';
import { default as ClientsService } from 'services/Clients';

// Get proxy callback from parent opener to make updates if possible
let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

const invoiceTemplate = {
	date: moment().format('YYYY-MM-DD'),
	business_data: {
		business_name: getBusiness().business_name,
		vatnumber: getBusiness().vatnumber,
		address: getBusiness().address,
		postalcode: getBusiness().postalcode,
		city: getBusiness().city,
		province: getBusiness().province,
		country: getBusiness().country
	},
	items: [],
	base: 0,
	total: 0,
	terms: null
};

export default function InvoiceForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const forceCanEdit = queryParams.get('edit') === 'true';
	const copyFromId = queryParams.get('copy_from_id');
	const fromReceiptId = queryParams.get('from_receipt_id');
	const isRefund = queryParams.get('refund') === 'true';

	let [data, setData] = useState({...invoiceTemplate});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data);
		};
	}, [data]);

	useEffect(() => {
		if ( params.id || copyFromId ) {
			const getData = async () => {
				setLoading(true);

				let id = null;
				if ( params.id ) id = params.id;
				if ( copyFromId ) id = copyFromId;
				
				let invoice = await InvoicesService.get(id, null);
				if ( invoice ) {
					let dataToSet = {
						...invoice,
						client: {...invoice.client_data},
						paymentmethod: {...invoice.paymentmethod_data}
					}

					// Copy
					if ( copyFromId ) {
						dataToSet.id = null;
						dataToSet.number = null;
						dataToSet.number_full = null;

						let datesDifference = null;
						if ( dataToSet.due_date ) {
							datesDifference = Math.abs(moment(dataToSet.date).diff(moment(dataToSet.due_date), 'days'));
						}

						dataToSet.date = moment().format('YYYY-MM-DD');
						if ( datesDifference !== null ) {
							dataToSet.due_date = moment(dataToSet.date).add(datesDifference, 'days').format('YYYY-MM-DD');
						} 

						dataToSet.items.forEach(el => {
							el.id = null;
							if ( isRefund ) {
								el.units *= -1;
								el.base *= -1;
								el.total *= -1;
							}
							return el;
						});

						dataToSet.base *= -1;
						dataToSet.total *= -1;
					}

					setData(dataToSet);

					if ( !forceCanEdit ) setCanEdit(false);
				}

				setLoading(false);
			}
			getData();
		}

		// Generate data from receipt
		const getDataFromReceipt = async () => {
			let receipt = await ReceiptsService.get(fromReceiptId);
			if ( receipt && !params.id ) {
				// Get client and merge with receipt client data
				let client = await ClientsService.get(receipt.client_id);
				if ( client ) {
					client = {...client, ...receipt.client_data};
				}

				// Update
				setData((prev) => ({
					...prev,
					client_id: receipt.client_id,
					client_data: {...client},
					associate_receipt_id: fromReceiptId
				}));
			} 
		}
		if ( fromReceiptId ) getDataFromReceipt();
	}, [params.id, copyFromId, fromReceiptId, forceCanEdit, isRefund]);

	// Generate invoice number
	useEffect(() => {
		if ( data.number || !data.serie?.id ) return;

		let generateInvoiceNumber = async () => {
			let nextNumber = await InvoicesService.getNextInvoiceNumber(data.serie.id, data.date);
			if ( nextNumber ) {
				setData((prev) => ({...prev, number: nextNumber.number, number_full: nextNumber.number_full}));	
			}
		}
		generateInvoiceNumber();

	}, [data.number, data.date, data.serie?.id]);
 
	const deleteInvoice = async () => {
		const c = window.confirm('¿Quieres eliminar esta factura?');
		if ( !c ) return;

		let result = await InvoicesService.delete(data.id);
		if ( result ) {
			toast.info('Factura borrada')

			if ( popup ) window.close();
			else navigate('/invoices');
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		if ( isRefund && copyFromId ) data.parent_id = copyFromId;

		let result = null;
		try {
			result = await InvoicesService.save(data);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			setData({
				...result.invoice,
				client: {...result.invoice.client_data},
				paymentmethod: {...result.invoice.paymentmethod_data}
			});
			
			if ( goBack ) {
				if ( popup ) {
					data = result.invoice; // Fix: prevent send incompleted data when popup is closed. Not a good way, but it solves the problem.
					window.close();
				} else navigate(-1);
			} else if ( !data.id ) {
		    	navigate('/invoices/edit/' + result.invoice.id + '?edit=true' +  (popup ? '&popup=true' : ''));
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}

		setLoading(false);
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const loadCategories = async (input, callback) => {
		let categories = await TradeCategoriesService.list('incomes', {
			search: input,
			no_paginate: true,
			with_breadcrumbs: true
		});
		
		let formatted = categories?.map((el) => {
			return {
				value: el, 
				label: (
					<div style={{paddingLeft: ((el.level-1) * 8) + 'px'}}>
						{el.level > 1 &&
							<>
								<i className="bi bi-arrow-return-right"></i>
								&nbsp;
							</>
						}
						{el.name}
					</div>
				)
			};
		});
		callback(formatted);
	}

	const selectCategory = useCallback((category) => {
		setData({
			...data, 
			category: {...category}
		});
	}, [data]);

	const loadSeries = async (input, callback) => {
		let series = await InvoicesSeriesService.list({
			search: input,
			no_paginate: true
		});

		let formatted = series?.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});
		callback(formatted);
	}

	const selectSerie = (serie) => {
		setData((prev) => ({
			...prev, 
			serie: {...serie},
			number: null
		}));
	}

	const selectClient = useCallback((client) => {
		setData((prev) => ({
			...prev, 
			client_id: client?.id,
			client_data: {...client}
		}));
	}, []);

	const loadPaymentMethods = async (input, callback) => {
		let paymentMethods = await PaymentMethodsService.list({
			search: input,
			no_paginate: true
		});

		let formatted = paymentMethods?.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});
		callback(formatted);
	}

	const selectPaymentMethod = useCallback((paymentmethod) => {
		setData({
			...data, 
			paymentmethod: {...paymentmethod},
		});
	}, [data]);

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	const getPdf = async () => {
		let result = await InvoicesService.getPdf(data.id);

		downloadFile(result, 'Factura ' + data.number_full + '.pdf');
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallback = (data, entity) => {
			if ( entity === 'paymentmethod' ) selectPaymentMethod(data);
			if ( entity === 'trade-category' ) selectCategory({...data, breadcrumbs: data.name});
			if ( entity === 'client' ) selectClient(data);
		}
	}, [selectPaymentMethod, selectCategory, selectClient]);

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Facturas emitidas | {(!data.id && !copyFromId) ? 'Nueva' : ''} {data.id ? 'Ficha' : ''} {(copyFromId && !isRefund) ? 'Copia' : ''} {(copyFromId && isRefund) ? 'Abono' : ''}</h1>
					<button onClick={() => popup ? window.close() : navigate('/invoices')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<LabeledFrame 
												label="Datos básicos" 
												labelSecondary={data.number_full ? <span className="text-white fw-bold">{data.number_full}</span> : null}
												labelSecondaryBackground="primary" 
												className="mb-3 mb-md-0"
											>
												<div className="row">
													<div className="col col-static-200">
														<div className="mb-2">
															<EmpoweredSelector
																load={(input, callback) => loadSeries(input, callback)}
																onChange={(value) => selectSerie(value)}
																timeout={250}
																label={data.serie?.name ?? ''}
																placeholder="Serie"
																showPlaceholderHelper={true}
																value={data.serie?.id}
																disabled={!canEdit} 
															/>
															{ errors['serie.id'] &&
																<div className="invalid-feedback d-block">{ errors['serie.id'][0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-100">
														<div className="mb-2">
															<CustomInput 
																label="Número" 
																type="number"
																step="1" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('number', e.target.value)} 
																value={data.number ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.number &&
																<div className="invalid-feedback d-block">{ errors.number[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-140">
														<div className="mb-2">
															<CustomInput 
																label="Fecha" 
																type="date" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('date', e.target.value)} 
																value={data.date ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.date &&
																<div className="invalid-feedback d-block">{ errors.date[0] }</div>
															}
														</div>
													</div>	
													<div className="col col-static-140">
														<div className="mb-2">
															<CustomInput 
																label="Fecha vencimiento" 
																type="date" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('due_date', e.target.value)} 
																value={data.due_date ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.due_date &&
																<div className="invalid-feedback d-block">{ errors.due_date[0] }</div>
															}
														</div>
													</div>	
												</div>	
											</LabeledFrame>
										</div>
										<div className="col-md-6">
											<LabeledFrame 
												label="Otros datos" 
												className="mb-3 mb-md-0"
											>
												<div className="row">
													<div className="col col-static-250">
														<div className="mb-2">
															<div className="input-group">
																<EmpoweredSelector
																	load={(input, callback) => loadPaymentMethods(input, callback)}
																	onChange={(value) => selectPaymentMethod(value)}
																	timeout={250}
																	label={data.paymentmethod?.name ?? ''}
																	placeholder="Forma de pago"
																	showPlaceholderHelper={true}
																	value={data.paymentmethod?.id}
																	disabled={!canEdit} 
																/>
																<div className="input-group-append">
																	<NavLink className="btn btn-light2 btn-sm text-secondary" to={'/payment-methods/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-plus-circle-fill"></i></NavLink>
																</div>
															</div>
															{ errors['paymentmethod.id'] &&
																<div className="invalid-feedback d-block">{ errors['paymentmethod.id'][0] }</div>
															}
														</div>
													</div>	
														
													<div className="col col-static-250">
														<div className="mb-0">
															<div className="input-group">
																<EmpoweredSelector
																	load={(input, callback) => loadCategories(input, callback)}
																	onChange={(value) => selectCategory(value)}
																	timeout={250}
																	label={
																		<div>
																			{data.category?.breadcrumbs ?? ''}&nbsp;
																		</div>
																	}
																	placeholder="Categoría"
																	showPlaceholderHelper={true}
																	value={data.category?.id}
																	disabled={!canEdit} 
																	dropDownMenuWidth={'130%'}
																/>
																<div className="input-group-append">
																	<NavLink className="btn btn-light2 btn-sm text-secondary" to={'/trade-categories/incomes/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-plus-circle-fill"></i></NavLink>
																</div>
															</div>
															{ errors['category.id'] &&
																<div className="invalid-feedback d-block">{ errors['category.id'][0] }</div>
															}
														</div>
													</div>
												</div>	
											</LabeledFrame>
										</div>
										<div className="col-md-12 mt-3">
											<BusinessPanel 
												data={data.business_data}
												setDataField={(field, value) => {
													setData({
														...data, 
														business_data: {
															...data.business_data,
															[field]: value
														}
													});
												}}
												errors={errors}
												readOnly={canEdit ? false : true}
											/>
										</div>
										<div className="col-md-12 mt-3">
											<ClientPanel 
												data={data.client_data}

												setData={(client) => {
													setData({
														...data,
														client_id: client?.id,
														client_data: {...client}
													})
												}}

												setDataField={(field, value) => {
													setData({
														...data, 
														client_data: {
															...data.client_data,
															[field]: value
														}
													});
												}}

												errors={errors}
												readOnly={canEdit ? false : true}
											/>
										</div>
										<div className="col-md-12 mt-3">
											<Items
												data={data}
												setData={setData}
												errors={errors}
												readOnly={!canEdit}
											/>
										</div>
										<div className="col-md-6 mt-3">
											<LabeledFrame label="Términos y condiciones" >
												<CustomTextarea
													className="form-control form-control-sm" 
													onChange={(e) => setDataField('terms', e.target.value)} 
													value={data.terms ?? ''} 
													readOnly={!canEdit} 
												/>
											</LabeledFrame>
										</div>	
										<div className="col-md-6 mt-3">
											<Payments
												data={data}
											/>
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && authUserPermission('delete')) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteInvoice()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ ((authUserPermission('add') && !data.id) || (authUserPermission('edit') && data.id)) &&
												<React.Fragment>
													{ data.id &&
														<>
															<div className="dropdown d-inline">
																<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																	Acciones
																</button>
																<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																	<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
																	<li><hr className="dropdown-divider" /></li>
																	<li><button className="dropdown-item" onClick={getPdf}>Descargar PDF</button></li>						
																	<li><hr className="dropdown-divider" /></li>
																	<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																	<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
																</ActionsContextMenu>
															</div>
														</>
													}

													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>
												</React.Fragment>
											}							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}