import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LoggedLayout from "layouts/LoggedLayout";
import PopupLayout from "layouts/PopupLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import EmpoweredSelector from 'components/EmpoweredSelector';
import BusinessChecker from 'components/BusinessChecker';
import { getBusiness, authUserPermission } from 'helpers/business';
import { formatNumber, downloadFile, openPopupWindow } from 'helpers/generic';
import BulkUploadButton from './BulkUploadButton';
import BulkDragAndDropUpload from './BulkDragAndDropUpload';
import DraftInvoices from './DraftInvoices';
import BulkActionsDropdown from './BulkActionsDropdown';
import {default as TradeCategoriesService} from 'services/TradeCategories';
import { default as PaymentMethodsService } from 'services/PaymentMethods';
import { default as ProviderInvoicesService } from 'services/ProviderInvoices';
import { default as FilesService } from 'services/Files';

const Table = styled.table`
	th,td {
		&:nth-child(1) {
			width: 20px;
		}

		&:nth-child(2) {
			width: 150px;
		}
	
		&:nth-child(3) {
			/* width: 100px; */
			white-space: nowrap;
		}

		&:nth-child(4) {
			width: 80px;
			text-align: right;
			white-space: nowrap;
		}

		&:nth-child(5) {
			text-align: right;
			white-space: nowrap;
		}

		&:nth-child(6) {
			width: 80px;
			text-align: center;
		}

		&:nth-child(7),
		&:nth-child(8) {
			width: 80px;
			text-align: center;
		}

		&:nth-child(10) {
			text-align: center;
			width: 30px;
			
			i {
				&::before {
					transform: scale(1.5);
				}
			}
		}


		@media (max-width: 768px) {
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(7),
			&:nth-child(8),
			&:nth-child(9) {
				display: none;
			}
		}
	}
`;

let searchTimeout = null;

export default function ProvidersInvoices() {
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const searchQS = queryParams.get('search') ?? undefined;

	let [forceReload, _setForceReload] = useState(null);
	let setForceReload = (value) => {
		_setForceReload(value);
		setSelected([]);
	}
	let [invoices, setInvoices] = useState({});
	let [search, setSearch] = useState(searchQS);
	let [selected, setSelected] = useState([]);
	let [dateFrom, setDateFrom] = useState( moment().startOf('year').format('YYYY-MM-DD') );
	let [dateTo, setDateTo] = useState( moment().endOf('year').format('YYYY-MM-DD') );
	let [dateReceptionFrom, setDateReceptionFrom] = useState(null);
	let [dateReceptionTo, setDateReceptionTo] = useState(null);
	let [category, setCategory] = useState({
		id: null,
		name: null
	});
	let [paymentmethod, setPaymentmethod] = useState({
		id: null,
		name: null
	});
	let [provider/*, setProvider*/] = useState({
		id: null,
		name: null
	});
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('date');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setInvoices({...invoices, data: undefined});
		_setPage(page);
	}
	let [paymentStatus, setPaymentStatus] = useState(null);


	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	useEffect(() => {
		const getInvoices = async () => {
			let invoices = await ProviderInvoicesService.list({
				page: 				page,
				search: 			search,
				category_id: 		category?.id,
				paymentmethod_id: 	paymentmethod?.id,
				provider_id: 		provider?.id,
				sort: 				sortField,
				direction: 			sortDirection,
				paid_status: 		paymentStatus,
				date_from: 			dateFrom,
				date_to: 			dateTo,
				date_reception_from:dateReceptionFrom,
				date_reception_to:	dateReceptionTo,
			});

			setInvoices({...invoices});
			setSkeletonRows(invoices.data.length);
		}

		getInvoices();
	}, [forceReload, page, search, category, paymentmethod, provider, sortField, sortDirection, paymentStatus, dateFrom, dateTo, dateReceptionFrom, dateReceptionTo]);

	const loadCategories = async (input, callback) => {
		let categories = await TradeCategoriesService.list('expenses', {
			search: input,
			no_paginate: true,
		});
		
		let formatted = categories?.map((el) => {
			return {
				value: el, 
				label: (
					<div style={{paddingLeft: ((el.level-1) * 8) + 'px'}}>
						{el.level > 1 &&
							<>
								<i className="bi bi-arrow-return-right"></i>
								&nbsp;
							</>
						}
						{el.name}
					</div>
				)
			};
		});
		callback(formatted);
	}

	const loadPaymentmethods = async (input, callback) => {
		let pmethods = await PaymentMethodsService.list({
			search: input,
			no_paginate: true,
		});
		
		let formatted = pmethods?.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});
		callback(formatted);
	}

	const viewDocument = (e, file) => {
		e.preventDefault();

		const baseUrl = e.currentTarget.href;
		if ( file.viewable ) return openPopupWindow(baseUrl + file.id);

		return downloadFile('/api/manager/documents/download-file/' + file.id);
	}

	const getDateRange = (range) => {
		let dateFrom = null;
		let dateTo = null;
		
		switch(range) {
			case 'thismonth':
				dateFrom = moment().startOf('month').format('YYYY-MM-DD');
				dateTo = moment().endOf('month').format('YYYY-MM-DD');
			break;

			case 'lastmonth':
				dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;

			case 'thisyear':
				dateFrom = moment().startOf('year').format('YYYY-MM-DD');
				dateTo = moment().endOf('year').format('YYYY-MM-DD');
			break;

			case 'lastyear':
				dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
			break;
			default: 
		}

		return {dateFrom, dateTo};;
	}

	const setDates = (e, range) => {
		e.preventDefault();

		let {dateFrom, dateTo} = getDateRange(range);
		
		setDateFrom(dateFrom);
		setDateTo(dateTo);
	}

	const setDateReceptions = (e, range) => {
		e.preventDefault();

		let {dateFrom, dateTo} = getDateRange(range);
		
		setDateReceptionFrom(dateFrom);
		setDateReceptionTo(dateTo);
	}

	const toggleCheckbox = (rowNumber) => {
		let newSelected = [...selected];

		let idx = newSelected.indexOf(rowNumber);
		if ( idx === - 1 ) newSelected.push(rowNumber);
		else newSelected.splice(idx, 1);

		setSelected(newSelected);
	}

	const download = async (e, expense) => {
		e.stopPropagation();

		let result = await FilesService.download(expense.file.id);
		downloadFile(result, expense.file.name);
	}

	const toggleAllCheckbox = () => {
		if ( selected.length === invoices.data?.length ) setSelected([]);
		else setSelected(invoices.data?.map((el, idx) => idx));
	}

	const deleteInvoice = async (e, invoice) => {
		e.preventDefault();
		e.stopPropagation();

		const c = window.confirm('¿Seguro que quieres eliminar la factura ' + invoice.number + '?');
		if ( !c ) return;

		let result = ProviderInvoicesService.delete(invoice.id);
		if ( result ) {
			toast.info('Factura borrada')
			setForceReload(Math.random());
			document.querySelector('body').click(); // Fix to close opened dropdown
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<BusinessChecker />
			<BulkDragAndDropUpload 
				business={getBusiness()} 
				permissions={authUserPermission('add')}
				parentReload={() => setForceReload(Math.random())} 
			>
				<section>
					<div className="page-title">
						<h1>Facturas recibidas</h1>
						<div className="d-flex ms-auto">
							{ authUserPermission('add') &&
								<>
									<BulkUploadButton 
										business={getBusiness()} 
										parentReload={() => setForceReload(Math.random())} 
									/>
									<NavLink to={'/providers-invoices/add' + (popup ? '?popup=true' : '')} className="btn btn-sm btn-light ms-2">Nueva factura</NavLink>
								</>
							}
						</div>
					</div>

					<div className="page-content">
						<DraftInvoices 
							business={getBusiness()} 
							parentReload={() => setForceReload(Math.random())} 
							reload={forceReload}
						/>

						<div className="card border-0 shadow-sm">
							<div className="card-header bg-white p-3">
								<div className="row">
									<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
										<EmpoweredSelector
											load={loadCategories}
											onChange={(value) => setCategory({id: value?.id, name: value?.name})}
											timeout={250}
											label={category?.name}
											placeholder="- Categoría -"
											value={category?.id}
										/>
									</div>
									<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
										<EmpoweredSelector
											load={loadPaymentmethods}
											onChange={(value) => setPaymentmethod({id: value?.id, name: value?.name})}
											timeout={250}
											label={paymentmethod?.name}
											placeholder="- Método de pago -"
											value={paymentmethod?.id}
										/>
									</div>
									<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
										<select className="form-control form-control-sm" value={paymentStatus ?? ''} onChange={(e) => setPaymentStatus(e.target.value)}>
											<option value="">- Estado del pago -</option>
											<option value="paid">Pagada</option>
											<option value="partial">Parcial</option>
											<option value="unpaid">No pagada</option>
											<option value="manual">Pago manual</option>
										</select>
									</div>
									<div className="col-md-3 mb-2 mt-2 mb-md-0 text-end">
										<input type="text" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} value={searchQS} />
									</div>
									<div className="col-md-4 mb-2 mt-2 mb-md-0 text-end">
										<div className="input-group input-group-sm">
											<span className="input-group-text">
												Fecha factura
											</span>
											<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
											<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
											<span className="input-group-btn">
												<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
												<ul className="dropdown-menu dropdown-menu-end">
													<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'thismonth')}>Este mes</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'lastmonth')}>Mes pasado</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'thisyear')}>Este año</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'lastyear')}>Año pasado</a></li>
												</ul>
											</span>
										</div>
									</div>
									<div className="col-md-4 mb-2 mt-2 mb-md-0 text-end">
										<div className="input-group input-group-sm">
											<span className="input-group-text">
												Fecha contable
											</span>
											<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateReceptionFrom ?? ''} onChange={(e) => setDateReceptionFrom(e.target.value)} />
											<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateReceptionTo ?? ''} onChange={(e) => setDateReceptionTo(e.target.value)} />
											<span className="input-group-btn">
												<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
												<ul className="dropdown-menu dropdown-menu-end">
													<li><a className="dropdown-item" href="." onClick={(e) => setDateReceptions(e, 'thismonth')}>Este mes</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDateReceptions(e, 'lastmonth')}>Mes pasado</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDateReceptions(e, 'thisyear')}>Este año</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDateReceptions(e, 'lastyear')}>Año pasado</a></li>
												</ul>
											</span>
										</div>
									</div>
									{selected.length > 0 &&
										<div className="col-md-2 mb-2 mt-2 mb-md-0">
											<BulkActionsDropdown 
												selectedItems={invoices.data?.map((el, idx) => selected.indexOf(idx) !== -1 ? el : null).filter(el => el != null)}
												parentForceReload={() => {
													setSelected([]);
													setForceReload(Math.random());
												}}
											/>
										</div>
									}
								</div>
							</div>
							<div className="card-body p-0">
								<div className="table-responsive table-responsive-carded">
									<Table className="table table-hover table-sortable table-carded">
										<thead>
											<tr>
												<th>
													<input 
														type="checkbox"
														onChange={() => toggleAllCheckbox()} 
														checked={invoices.data?.length > 0 && selected.length === invoices.data?.length} 
													/>
												</th>
												<ThSortable direction={sortDirection} active={sortField === 'provider.name'} onClick={() => sortTableClick('provider.name')}>Proveedor</ThSortable>
												<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.number'} onClick={() => sortTableClick('providers_invoices.number')}>Nº</ThSortable>
												<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.date'} onClick={() => sortTableClick('providers_invoices.date')}>Fecha</ThSortable>
												<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.base'} onClick={() => sortTableClick('providers_invoices.base')}>Base</ThSortable>
												<th>Impuestos</th>
												<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.total'} onClick={() => sortTableClick('providers_invoices.total')}>Total</ThSortable>
												<th>Pagada</th>
												<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.date_reception'} onClick={() => sortTableClick('providers_invoices.date_reception')}>Fecha contable</ThSortable>
												<th style={{width: '40px'}}></th>
											</tr>
										</thead>
										<tbody>
											{ invoices.data?.length > 0 &&
												invoices.data.map((el, idx) => {
													return ( 
														<React.Fragment key={'p'+idx}>
															<tr className="has-details" onClick={() => toggleDetails(el.id)}>
																<td>
																	<input 
																		type="checkbox" 
																		onClick={(e) => e.stopPropagation()} 
																		onChange={(e) => toggleCheckbox(idx)} 
																		checked={selected.indexOf(idx) !== -1} 
																	/>
																</td>
																<td>
																	{el.provider?.name ?? '-'}
																	<div><small>{el.provider?.vatnumber}</small></div>
																</td>
																<td>
																	{ el.number }
																	{ !el.file_id && 
																		<div className="text-danger small">Fichero pendiente de adjuntar</div>
																	}
																</td>
																<td>{ el.date ? moment(el.date).format('DD-MM-YYYY') : '-' }</td>
																<td>{ formatNumber(el.base) } €</td>
																<td>
																	{el.taxes?.map((iEl, iIdx) => {
																		return <div key={iIdx}>{iEl.name}: {formatNumber(iEl.amount, 2)} €</div>
																	})}
																</td>
																<td>{ formatNumber(el.total) } €</td>
																<td>
																	{ el.paid_status === 'manual' && <span className="badge bg-success">Manual</span>}
																	{ el.paid_status === 'full' && <span className="badge bg-success">Si</span>}
																	{ el.paid_status === 'unpaid' && <span className="badge bg-danger">No</span>}
																	{ el.paid_status === 'partial' && <span className="badge bg-indigo">Parcial</span>}
																</td>
																<td>{ el.date_reception ? moment(el.date_reception).format('DD-MM-YYYY') : '-' }</td>
																
																<td>
																	<div className="dropdown">
																		<button className="btn btn-sm btn-table p-0 px-1 text-primary" type="button" data-bs-toggle="dropdown" onClick={(e) => e.stopPropagation()}>
																			<i className="bi bi-three-dots-vertical"></i>
																		</button>
																		<ul className="dropdown-menu dropdown-tablerow-actions">
																			{ authUserPermission('edit') &&
																				<li>
																					<NavLink className="dropdown-item" to={'/providers-invoices/edit/' + el.id + (popup ? '?popup=true' : '')}>
																						<i className="bi bi-pencil"></i>&nbsp;
																						Editar
																					</NavLink>
																				</li>
																			}
																			{/* { authUserPermission('add') &&
																				<li>
																					<NavLink className="dropdown-item" to={'/providers-invoices/add?copy_from_id=' + el.id + '&edit=true' + (popup ? '&popup=true' : '')}>
																						<i className="bi bi-cash"></i>&nbsp;
																						Copiar
																					</NavLink>
																				</li>
																			} */}
																			
																			{el.file_id &&
																				<>
																					<li><hr className="dropdown-divider" /></li>
																					<li>
																						<button className="dropdown-item" onClick={(e) => download(e, el)}>
																							<i className="bi bi-download me-1"></i>&nbsp;
																							Descargar
																						</button>
																					</li>
																				</>
																			}
																			<li><hr className="dropdown-divider" /></li>
																			{ authUserPermission('delete') &&
																				<li>
																					<NavLink className="dropdown-item" to={'delete-expense-' + el.id} onClick={(e) => deleteInvoice(e, el)}>
																						<i className="bi bi-x"></i>&nbsp;
																						Eliminar
																					</NavLink>
																				</li>
																			}
																		</ul>
																	</div>
																</td>
															</tr>
															{ detailsOpened.includes(el.id) && 	
																<tr className="details">
																	<td colSpan="100%">
																		<div className="row ms-0 me-0">
																			<div className="col-md-6">
																				<div><b className="sbold">Proveedor</b>: {el.provider?.business_name ?? '-'}</div>
																				<div><b className="sbold">Nº documento</b>: {el.number ?? '-'}</div>
																				<div><b className="sbold">Fecha</b>: {el.date ? moment(el.date).format('DD-MM-YYYY') : '-'}</div>
																				<div className="mb-3"><b className="sbold">Fecha de recepción</b>: {el.date_reception ? moment(el.date_reception).format('DD-MM-YYYY') : '-'}</div>

																				<div><b className="sbold">Base imponible</b>: { formatNumber(el.base) } €</div>
																				<div><b className="sbold">Total</b>: { formatNumber(el.total) } €</div>
																				<div>
																					<b className="sbold">Pagada</b>:&nbsp; 
																					{ el.paid_status === 'manual' && <span className="badge bg-success">Manual</span>}
																					{ el.paid_status === 'full' && <span className="badge bg-success">Si</span>}
																					{ el.paid_status === 'unpaid' && <span className="badge bg-danger">No</span>}
																					{ el.paid_status === 'partial' && <span className="badge bg-indigo">Parcial</span>}
																				</div>
																			</div>
																			<div className="col-md-6">
																				<div><b className="sbold">Forma de pago</b>: {el.paymentmethod?.name ?? '-'}</div>
																				<div><b className="sbold">Categoría</b>: {el.category?.name ?? '-'}</div>
																				<div><b className="sbold">Descripción</b>: {el.description ?? '-'}</div>
																				<div><b className="sbold">Documento asociado</b>: {el.file ? <NavLink to="/documents/view-file/" onClick={(e) => viewDocument(e, el.file)}>{el.file.name}</NavLink> : '-'}</div>
																			</div>
																		</div>
																	</td>
																</tr>
															}
														</React.Fragment>
													);
												})
											}

											{ invoices.data && !invoices.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

											{ invoices.data === undefined && <TrSkeleton rows={skeletonRows} columns={11} /> }
										</tbody>
										<tfoot>
											<tr className="sbold">
												<td colSpan="5"></td>
												<td className="text-end">{ formatNumber(invoices.total_sum) } €</td>
												<td colSpan="4"></td>
											</tr>
										</tfoot>
									</Table>
								</div>
							</div>
							<div className="card-footer p-3 d-flex justify-content-end">
								<div className="d-inline-block">
									<Paginator
										min={1}
										current={invoices?.current_page}
										max={invoices?.last_page}
										changeCallback={(page) => setPage(page)}
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
			</BulkDragAndDropUpload>
		</Layout>
	);
}


