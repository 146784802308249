import React, { useRef } from "react";
import { Navigate, NavLink } from "react-router-dom";
import { toast } from 'react-toastify';
import { getUser, setUser } from 'helpers/user';
import { setConfig } from 'helpers/config';
import useStore from "store";
import { default as AuthService } from 'services/Auth';

export default function Login() {
	const usernameRef = useRef();
	const passwordRef = useRef();

	const debug = useStore(state => state.debug);
 
	const login = async (e) => {
		e.preventDefault();

		let result = await AuthService.login(usernameRef.current.value, passwordRef.current.value);
		if ( !result ) {
			toast.error('Datos inválidos');
		} else {
			setConfig(result.config);
			setUser(result.user, true);
		}
	};

	if ( getUser() ) return <Navigate to="/" />;

	return (
		<div className="container">
			<div className="row justify-content-center mt-5">
				<div className="col-md-4">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title text-center mb-0">Acceso</h4>
						</div>
						<div className="card-body">
							<form onSubmit={(e) => login(e)}>
								<div className="mb-3">
									{ debug &&
										<select ref={usernameRef} className="form-control form-control-sm">
											<option value="admin@admin.com">Admin</option>
											<option value="usuario@usuario.com">Usuario</option>
										</select>
									}
									{ !debug &&
										<input type="text" ref={usernameRef} className="form-control form-control-sm" placeholder="Usuario" />
									}
								</div>
								<div className="mb-3">
									<input type="password" ref={passwordRef} defaultValue={debug ? '123456' : ''} className="form-control form-control-sm" placeholder="Contraseña" />
								</div>
								<div className="text-center">
									<button className="btn btn-primary btn-sm text-white" onClick={(e) => login(e)}>Entrar</button>	
								</div>
								<div className="text-center mt-2">
									<NavLink to="/reset-password" className="btn btn-link btn-sm text-dark">¿Has olvidado tu contraseña?</NavLink>	
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}