import React, { useState } from "react";
import styled from "styled-components";
import CustomInput from "components/CustomInput";
import LabeledFrame from "components/LabeledFrame";
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as AfterBanksService } from "services/AfterBanks";

const BankRow = styled.div`
    display: flex;
    align-items: center;

    .image {
        width: 20px;
        margin-right: 5px;

        img {
            width: 100%;
        }
    }

    .business {
        font-size: 9px;
    }
`;

let setPasswordInputTypeTimeout = null;

export default function BankReconciliationCredentialsPanel(props) {
    let data = props.data;
    let setDataField = props.setDataField;
    let errors = props.errors;
    let canEdit = props.readOnly ? false : true;

    let [passwordInputType, setPasswordInputType] = useState('password');

    const loadBanksList = async (input, callback) => {
        let banks = await AfterBanksService.getBanksList(input);

        let formatted = banks?.map((el) => {
			return {
				value: el, 
				label: generateBankRow(el)
			};
		});
		callback(formatted);
    }

    const testConnection = async () => {
        let result = await AfterBanksService.testConnection(data.afterbanks);

        let newAfterbanks = {...data?.afterbanks};
        newAfterbanks.connected = result.status;

        setDataField('afterbanks', newAfterbanks);
    }

    const setAfterBanksField = (idx, field, value) => {
        let newAfterbanks = {...data.afterbanks};
        
        if ( newAfterbanks.fields && newAfterbanks.fields[idx] ) newAfterbanks.fields[idx].value = value;

        setDataField('afterbanks', newAfterbanks);
    }

    console.log(data);

    return (
        <LabeledFrame 
            label="Credenciales para conciliación bancaria"
            className="mb-3 mb-md-0"
        >
            <div className="row">
                <div className="col col-static-200">
                    <div className="mb-2">
                        <div className="input-group">
                            <EmpoweredSelector
                                load={(input, callback) => loadBanksList(input, callback)}
                                onChange={(value) => {
                                    setDataField('afterbanks', value)
                                }}
                                timeout={250}
                                label={data?.afterbanks ? generateBankRow(data.afterbanks) : null}
                                placeholder="Banco"
                                showPlaceholderHelper={true}
                                value={data?.afterbanks ?? null}
                                disabled={!canEdit}
                            />
                            {data?.afterbanks?.code &&
                                <div className="input-group-append">
                                    <button className={'btn btn-sm text-white btn-' + (data?.afterbanks?.connected ? 'success' : 'danger')} onClick={testConnection}><i className={'bi bi-plug' + (data?.afterbanks?.connected ? '-fill' : '')}></i></button>
                                </div>
                            }
                        </div>
                        
                        { errors['credentials.afterbanks.name'] &&
                            <div className="invalid-feedback d-block">{ errors['credentials.afterbanks.name'][0] }</div>
                        }
                    </div>
                </div>

                {data?.afterbanks?.fields &&
                    data.afterbanks.fields.map((el, idx) => {
                        return (
                            <div key={idx} className="col col-static-150">
                                <div className="mb-2">
                                    <CustomInput 
                                        label={el.name}
                                        type={passwordInputType} 
                                        className="form-control form-control-sm" 
                                        onChange={(e) => setAfterBanksField(idx, el.key, e.target.value)}
                                        onKeyPress={(e) => {
                                            setPasswordInputType('text');
            
                                            clearTimeout(setPasswordInputTypeTimeout);
                                            setPasswordInputTypeTimeout = setTimeout(() => {
                                                setPasswordInputType('password');
                                            }, 500);
                                        }} 
                                        value={data.afterbanks.fields[idx].value ?? ''} 
                                        readOnly={!canEdit}
                                    />
                                </div>
                            </div>
                        );
                    })
                }

                {/* <div className="col col-static-150">
                    <div className="mb-2">
                        <CustomInput 
                            label="Usuario" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('username', e.target.value)} 
                            value={data?.username ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['credentials.username'] &&
                            <div className="invalid-feedback d-block">{ errors['credentials.username'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-150">
                    <div className="mb-2">
                        <CustomInput 
                            label="Contraseña" 
                            type={passwordInputType} 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('password', e.target.value)}
                            onKeyPress={(e) => {
                                setPasswordInputType('text');

                                clearTimeout(setPasswordInputTypeTimeout);
                                setPasswordInputTypeTimeout = setTimeout(() => {
                                    setPasswordInputType('password');
                                }, 500);
                            }} 
                            value={data?.password ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['credentials.password'] &&
                            <div className="invalid-feedback d-block">{ errors['credentials.password'][0] }</div>
                        }
                    </div>
                </div> */}
                
            </div>	
        </LabeledFrame>
    );
}

const generateBankRow = (data) => {

    return (
        <BankRow>
            {data.image_url &&
                <div className="image">
                    <img src={data.image_url} alt="logo" />
                </div>
            }
            <div className="data">
                <div className="name">{data.name}</div>
                {data.business &&
                    <div className="business">negocios</div>
                }
            </div>
        </BankRow>
    );
}