import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import PopupLayout from "layouts/PopupLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import EmpoweredSelector from 'components/EmpoweredSelector';
import { formatNumber, openPopupWindow } from 'helpers/generic';
import { default as ReceiptsService } from 'services/Receipts';
import { default as ClientsService } from 'services/Clients';
import { default as InvoicesService } from 'services/Invoices';
import BusinessChecker from 'components/BusinessChecker';
import { authUserPermission } from 'helpers/business';
import BulkActionsDropdown from './BulkActionsDropdown';

const Table = styled.table`
	tr {
		&:not(.details) {
			td {
				&:nth-child(1) {
					vertical-align: top !important;

					& > input {
						margin-top: 4px;
					}
				}
			}

			th, td {
				&:nth-child(1) {
					width: 15px;
					pointer-events: none;
					vertical-align: middle;

					& > input {
						pointer-events: initial;
					}
				}
			
				&:nth-child(2) {
					width: 80px;
					white-space: nowrap;
				}

				&:nth-child(3) {
					width: 100px;
					white-space: nowrap;
				}

				&:nth-child(5) {
					width: 120px;
					white-space: nowrap;
				}

				&:nth-child(6) {
					width: 80px;
					text-align: right;
					white-space: nowrap;
				}

				&:nth-child(7) {
					width: 80px;
					text-align: right;
					white-space: nowrap;
				}

				&:nth-child(8) {
					width: 30px;
					text-align: center;
					
					i {
						&::before {
							transform: scale(1.5);
						}
					}
				}

				@media (max-width: 768px) {
					&:nth-child(4),
					&:nth-child(6) {
						display: none;
					}
				}
			}
		}
	}
`;

let searchTimeout = null;

const dateFromDefault = moment().startOf('year').format('YYYY-MM-DD');
const dateToDefault = moment().endOf('year').format('YYYY-MM-DD');

export default function Receipts() {
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const searchQS = queryParams.get('search') ?? undefined;
	const invoiceId = queryParams.get('invoice_id') ?? undefined;

	let [forceReload, setForceReload] = useState(null);
	let [receipts, setReceipts] = useState({});
	let [selected, setSelected] = useState([]);
	let [search, setSearch] = useState(searchQS);
	let [dateFrom, setDateFrom] = useState(dateFromDefault);
	let [dateTo, setDateTo] = useState(dateToDefault);
	let [client, setClient] = useState({
		id: null,
		name: null
	});
	let [withRemittance, setWithRemittance] = useState('');

	let [invoice, _setInvoice] = useState({});
	const setInvoice = (invoice) => {
		_setInvoice(invoice);
		setDateFrom(invoice ? null : dateFromDefault);
		setDateTo(invoice ? null : dateToDefault);
	}

	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('number.full');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setReceipts({...receipts, data: undefined});
		_setPage(page);
	}
	let [paymentStatus, setPaymentStatus] = useState(null);


	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	// Proxy for popup window
	useEffect(() => {
		window.PopupProxyCallback = () => {
			setForceReload(Math.random());
		}
	}, []);

	useEffect(() => {
		const getReceipts = async () => {
			let receipts = await ReceiptsService.list({
				page: 				page,
				search: 			search,
				client_id: 			client?.id,
				sort: 				sortField,
				direction: 			sortDirection,
				paid_status: 		paymentStatus,
				date_from: 			dateFrom,
				date_to: 			dateTo,
				invoice_id:			invoice?.id,
				with_remittance:	withRemittance
			});

			setReceipts({...receipts});
			setSkeletonRows(receipts?.data?.length);
		}

		getReceipts();
	}, [page, search, client, sortField, sortDirection, paymentStatus, dateFrom, dateTo, invoice?.id, withRemittance, forceReload]);

	// Get invoice to show on filters
	useEffect(() => {
		if ( !invoiceId ) return;
 		let getInvoice = async () => {
			let invoice = await InvoicesService.get(invoiceId);
			if ( invoice ) setInvoice(invoice);
		}
		getInvoice();
	}, [invoiceId]);

	const loadClients = async (input, callback) => {
		let clients = await ClientsService.list({
			search: input,
			no_paginate: true,
		});
		
		let formatted = clients?.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});
		callback(formatted);
	}

	const setDatesRange = (e, range) => {
		e.preventDefault();

		let dateFrom = null;
		let dateTo = null;
		
		switch(range) {
			case 'thismonth':
				dateFrom = moment().startOf('month').format('YYYY-MM-DD');
				dateTo = moment().endOf('month').format('YYYY-MM-DD');
			break;

			case 'lastmonth':
				dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;

			case 'firstquarter':
				dateFrom = moment().set('month', 0).startOf('month').format('YYYY-MM-DD');
				dateTo = moment().set('month', 2).endOf('month').format('YYYY-MM-DD');
			break;

			case 'secondquarter':
				dateFrom = moment().set('month', 3).startOf('month').format('YYYY-MM-DD');
				dateTo = moment().set('month', 5).endOf('month').format('YYYY-MM-DD');
			break;

			case 'thirdquarter':
				dateFrom = moment().set('month', 6).startOf('month').format('YYYY-MM-DD');
				dateTo = moment().set('month', 8).endOf('month').format('YYYY-MM-DD');
			break;

			case 'fourthquarter':
				dateFrom = moment().set('month', 9).startOf('month').format('YYYY-MM-DD');
				dateTo = moment().set('month', 11).endOf('month').format('YYYY-MM-DD');
			break;

			case 'thisyear':
				dateFrom = moment().startOf('year').format('YYYY-MM-DD');
				dateTo = moment().endOf('year').format('YYYY-MM-DD');
			break;

			case 'lastyear':
				dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
			break;
			default: 
		}

		setDateFrom(dateFrom);
		setDateTo(dateTo);
	}

	const toggleCheckbox = (rowNumber) => {
		let newSelected = [...selected];

		let idx = newSelected.indexOf(rowNumber);
		if ( idx === - 1 ) newSelected.push(rowNumber);
		else newSelected.splice(idx, 1);

		setSelected(newSelected);
	}

	const toggleAllCheckbox = () => {
		if ( selected.length === receipts.data?.length ) setSelected([]);
		else setSelected(receipts.data?.map((el, idx) => idx));
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.target.href, 'receipts-list');
	}

	const deleteReceipt = async (e, receipt) => {
		e.preventDefault();
		e.stopPropagation();

		const c = window.confirm('¿Seguro que quieres eliminar el recibo ' + receipt.number_full + '?');
		if ( !c ) return;

		let result = ReceiptsService.delete(receipt.id);
		if ( result ) {
			toast.info('Recibo borrado')
			setForceReload(Math.random());
			document.querySelector('body').click(); // Fix to close opened dropdown
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<BusinessChecker />
			<section>
				<div className="page-title">
					<h1>Recibos</h1>
					
					<NavLink to={'/receipts/add' + (popup ? '?popup=true' : '')} className="btn btn-sm btn-light ms-auto">Nuevo recibo</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" value={paymentStatus ?? ''} onChange={(e) => setPaymentStatus(e.target.value)}>
										<option value="">- Estado del pago -</option>
										<option value="paid">Pagado</option>
										<option value="unpaid">No pagado</option>
										<option value="refunded">Devuelto</option>
									</select>
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									<div className="input-group input-group-sm">
										<span className="input-group-text">
											Fecha vcto.
										</span>
										<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
										<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
										<span className="input-group-btn">
											<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
											<ul className="dropdown-menu dropdown-menu-end">
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'thismonth')}>Este mes</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'lastmonth')}>Mes pasado</a></li>
												<li><hr className="dropdown-divider" /></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'firstquarter')}>Primer trimestre</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'secondquarter')}>Segundo trimestre</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'thirdquarter')}>Tercer trimestre</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'fourthquarter')}>Cuarto trimestre</a></li>
												<li><hr className="dropdown-divider" /></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'thisyear')}>Este año</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'lastyear')}>Año pasado</a></li>
											</ul>
										</span>
									</div>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} value={searchQS} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadClients}
										onChange={(value) => setClient({id: value?.id, name: value?.name})}
										timeout={250}
										label={client?.name}
										placeholder="- Cliente -"
										value={client?.id}
									/>
								</div>
								<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" value={withRemittance} onChange={(e) => setWithRemittance(e.target.value)}>
										<option>- Remesados -</option>
										<option value="true">Si</option>
										<option value="false">No</option>
									</select>
								</div>
								{selected.length > 0 &&
									<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
										<BulkActionsDropdown 
											selectedItems={receipts.data?.map((el, idx) => selected.indexOf(idx) !== -1 ? el : null).filter(el => el != null)}
											parentForceReload={() => {
												setSelected([]);
												setForceReload(Math.random());
											}}
										/>
									</div>
								}
								{invoice?.id &&
									<div className="col-md-12 mt-2 text-secondary">
										Estás viendo los recibos asociados asociados a la factura {invoice.number_full} <i className="bi bi-x text-danger cursor-pointer" onClick={() => setInvoice(null)}></i>
									</div>
								}
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>
												<input 
													type="checkbox"
													onChange={() => toggleAllCheckbox()} 
													checked={receipts.data?.length > 0 && selected.length === receipts.data?.length} 
												/>
											</th>
											<ThSortable direction={sortDirection} active={sortField === 'number.full'} onClick={() => sortTableClick('number.full')}>Nº</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'due_date'} onClick={() => sortTableClick('due_date')}>Fecha vencimiento</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'clientdata.name'} onClick={() => sortTableClick('clientdata.name')}>Cliente</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'remittance.number'} onClick={() => sortTableClick('remittance.number')}>Remesa</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'amount'} onClick={() => sortTableClick('amount')}>Importe</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'paid.status'} onClick={() => sortTableClick('paid.status')}>Pagado</ThSortable>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ receipts.data?.length > 0 &&
											receipts.data.map((el, idx) => {
												return ( 
													<React.Fragment key={'p'+idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>
																<input 
																	type="checkbox" 
																	onClick={(e) => e.stopPropagation()} 
																	onChange={(e) => toggleCheckbox(idx)} 
																	checked={selected.indexOf(idx) !== -1} 
																/>
															</td>
															<td>{ el.number_full }</td>
															<td>{ el.due_date ? moment(el.due_date).format('DD-MM-YYYY') : '-' }</td>
															<td>
																{el.client_data.name ?? '-'}
																<div><small>{el.client_data.vatnumber}</small></div>
															</td>
															<td>
																{el.remittance &&
																	<>
																		<div>{el.remittance.number_full}</div>
																		<small>{moment(el.remittance.payment_date).format('DD-MM-YYYY')}</small>
																	</>
																}
															</td>
															<td>{ formatNumber(el.amount) } €</td>
															<td>
																{ el.paid_status === 'paid' && <span className="badge bg-success">Si</span>}
																{ el.paid_status === 'unpaid' && <span className="badge bg-danger">No</span>}
															</td>
															<td>
																<div className="dropdown">
																	<button className="btn btn-sm btn-table p-0 px-1 text-primary" type="button" data-bs-toggle="dropdown" onClick={(e) => e.stopPropagation()}>
																		<i className="bi bi-three-dots-vertical"></i>
																	</button>
																	<ul className="dropdown-menu dropdown-tablerow-actions">
																		<li>
																			<NavLink className="dropdown-item" to={'/receipts/edit/' + el.id + (popup ? '?popup=true' : '')}>
																				<i className="bi bi-pencil"></i>&nbsp;
																				Editar
																			</NavLink>
																		</li>
																		{authUserPermission('add') &&
																			<li>
																				<NavLink className="dropdown-item" to={'/receipts/add?copy_from_id=' + el.id + '&edit=true' + (popup ? '&popup=true' : '')}>
																					<i className="bi bi-bank"></i>&nbsp;
																					Copiar recibo
																				</NavLink>
																			</li>
																		}
																		<li><hr className="dropdown-divider" /></li>
																		{el.invoice_id &&
																			<li>
																				<NavLink onClick={openPopupInfoWindow} className="dropdown-item" to={'/invoices/edit/' + el.invoice_id + '?popup=true'}>
																					<i className="bi bi-receipt"></i>&nbsp;
																					Ver factura
																				</NavLink>
																			</li>
																		}
																		{ authUserPermission('add') &&
																			<>
																				{!el.invoice_id &&
																					<li>
																						<NavLink onClick={openPopupInfoWindow} className="dropdown-item" to={'/invoices/add?popup=true&from_receipt_id='+el.id+'&first_item_concept='+el.concept+'&first_item_total='+el.amount}>
																							<i className="bi bi-receipt"></i>&nbsp;
																							Crear factura
																						</NavLink>
																					</li>
																				}
																			</>
																		}
																		
																		{ (el.is_deletable && authUserPermission('delete')) &&
																			<>
																				<li><hr className="dropdown-divider" /></li>
																				<li>
																					<NavLink className="dropdown-item" to={'delete-receipt-' + el.id} onClick={(e) => deleteReceipt(e, el)}>
																						<i className="bi bi-x"></i>&nbsp;
																						Eliminar
																					</NavLink>
																				</li>
																			</>
																		}
																	</ul>
																</div>
															</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<div className="col-md-6">
																			<div><b className="sbold">Cliente</b>: {el.client_data?.name ?? '-'}</div>
																			<div><b className="sbold">Nº</b>: {el.number_full ?? '-'}</div>
																			<div className="mb-3"><b className="sbold">Fecha vencimiento</b>: {el.due_date ? moment(el.due_date).format('DD-MM-YYYY') : '-'}</div>
																			
																			<div><b className="sbold">Concepto</b>: { el.concept }</div>
																			<div><b className="sbold">Importe</b>: { formatNumber(el.amount) } €</div>
																			<div>
																				<b className="sbold">Pagado</b>:&nbsp; 
																				{ el.paid_status === 'paid' && <span className="badge bg-success">Si</span>}
																				{ el.paid_status === 'unpaid' && <span className="badge bg-danger">No</span>}
																			</div>
																		</div>
																		<div className="col-md-6">
																			<div><b className="sbold">Cuenta bancaria</b>: {el.bankaccount_data?.iban ?? '-'}</div>
																		</div>
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ receipts.data && !receipts.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ receipts.data === undefined && <TrSkeleton rows={skeletonRows} columns={8} /> }
									</tbody>
									<tfoot>
										<tr className="sbold">
											<td colSpan="5"></td>
											<td className="text-end">{ formatNumber(receipts.total_sum) } €</td>
											<td colSpan="2"></td>
										</tr>
									</tfoot>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={receipts?.current_page}
									max={receipts?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}


