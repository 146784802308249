import axios from 'axios';

export default class Taxes {

	static axiosCancelToken = null;

	static list = (params) => {
		this.newAxiosCancelToken();

		return axios.get('api/taxes/list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static get = (id) => {
		this.newAxiosCancelToken();

		return axios.get('api/taxes/get/' + id, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static save = (data) => {
		this.newAxiosCancelToken();

		return axios.post('api/taxes/save' + (data.id ? '/' + data.id : ''), data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data.errors ) return error.response.data;
		});	
	}

	static delete = (id) => {
		this.newAxiosCancelToken();

		return axios.delete('/api/taxes/delete/' + id, {}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
	    	return true;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return false;
		});	
	}

	static undelete = (id) => {
		this.newAxiosCancelToken();

		return axios.post('/api/taxes/undelete/' + id, {}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
	    	return true;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return false;
		});	
	}

	static getLastOrder = (id) => {
		this.newAxiosCancelToken();

		return axios.get('api/taxes/get-last-order', {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}