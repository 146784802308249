import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import { CristalLoader } from 'helpers/generic';
import FieldSmallComment from 'components/FieldSmallComment';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import { default as TaxesService } from 'services/Taxes';

export default function TaxesForm(props) {
	const navigate = useNavigate();
	const params = useParams();

	let [data, setData] = useState({
		default: false
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				let tax = await TaxesService.get(params.id);
				if (tax) setData({...tax});

				setLoading(false);
			}
			getData();
		} else {
			const getLastOrder = async () => {
				setLoading(true);

				let lastOrder = await TaxesService.getLastOrder();
				if (lastOrder) setData(data => ({...data, order: lastOrder.order}));

				setLoading(false);
			}
			getLastOrder();
		}
	}, [params.id]);

	const deleteTax = async () => {
		const c = window.confirm('¿Quieres eliminar este impuesto?');
		if ( !c ) return;

		let result = await TaxesService.delete(data.id);
		if ( result ) {
			toast.success('Impuesto borrado')
	    	navigate('/taxes');
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const undeleteTax = async () => {
		const c = window.confirm('¿Quieres desarchivar este impuesto?');
		if ( !c ) return;

		let result = await TaxesService.undelete(data.id);
		if ( result ) {
			toast.success('Impuesto desarchivado')
	    	navigate('/taxes');
		} else {
			toast.error('Ha ocurrido un error al desarchivar');
		}
	}

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		let result = null;
		try {
			result = await TaxesService.save(data);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			
			if ( goBack ) navigate('/taxes');
			else {
		    	navigate('/taxes/edit/' + result.tax.id);
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	return (
		<LoggedLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de impuesto</h1>
					<button onClick={() => navigate('/taxes')} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="row">
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput 
														label="Nombre" ç
														type="text" 
														className="form-control form-control-sm" 
														onChange={(e) => setDataField('name', e.target.value)} 
														value={data.name ?? ''} 
													/>
													{ errors.name &&
														<div className="invalid-feedback d-block">{ errors.name[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput 
														label="Porcentaje" 
														type="number" 
														step="0.01" 
														className="form-control form-control-sm" 
														onChange={(e) => setDataField('percent', e.target.value)} 
														value={data.percent ?? ''} 
														readOnly={data.is_used}
													/>
													{ errors.percent &&
														<div className="invalid-feedback d-block">{ errors.percent[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput 
														label="Orden" 
														type="number" 
														className="form-control form-control-sm" 
														onChange={(e) => setDataField('order', e.target.value)} 
														value={data.order ?? ''} 
													/>
													<FieldSmallComment className="mt-1">Este campo determina el orden en el que apareceran los impuestos en los formularios</FieldSmallComment>
													{ errors.order &&
														<div className="invalid-feedback d-block">{ errors.order[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomSelect 
														label="Por defecto" 
														className="form-control form-control-sm" 
														onChange={(e) => setDataField('default', e.target.value)} 
														value={data.default ?? ''}
													>
														<option value="0">No</option>
														<option value="1">Si</option>
													</CustomSelect>
													<FieldSmallComment className="mt-1">Si se marca por defecto aparecerá como impuesto preseleccionado en los formularios donde se tenga que seleccionar un impuesto</FieldSmallComment>
													{ errors.default &&
														<div className="invalid-feedback d-block">{ errors.default[0] }</div>
													}
												</div>
											</div>
										</div>	
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-4">
											{ data.id &&
												<React.Fragment>
													{data.deleted_at ?
														<button className="btn btn-sm btn-link text-success" tabIndex="-1" onClick={() => undeleteTax()}>Desarchivar</button>
														:
														<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteTax()}>{data.is_used ? 'Archivar' : 'Eliminar'}</button>							
													}
												</React.Fragment>
											}
										</div>
										<div className="col-8 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}