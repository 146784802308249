import React, { useRef } from 'react';
import styled from 'styled-components';

const CustomInputCheckboxStyled = styled.div`
	position: relative;
    min-height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span.custom-input-checkbox-label {
        /* font-size: 9px; */
        color: var(--bs-primary);
        user-select: none;
        margin-left: 5px;
    }

    input[type=checkbox] {
        ${props => props.size === 'md' && 'width: 15px; height: 15px;'}
        ${props => props.size === 'lg' && 'width: 20px; height: 20px;'}
    }
`;

export default function CustomCheckbox(props) {
    let inputRef = useRef(null);

    let inputProps = {...props};
    if ( inputProps.hasOwnProperty('placeholder') ) delete inputProps.placeholder;
    if ( inputProps.hasOwnProperty('label') ) delete inputProps.label;
    if ( inputProps.hasOwnProperty('type') ) delete inputProps.type;
    if ( inputProps.hasOwnProperty('size') ) delete inputProps.size;

    let label = props.placeholder ?? null;
    if ( props.label ) label = props.label ?? null;
	
	return (
		<CustomInputCheckboxStyled className="custom-input-checkbox" size={props.size ?? 'md'}>
			<input type="checkbox" ref={inputRef} {...inputProps} />
            {label && <span className="custom-input-checkbox-label" onClick={() => inputRef.current.click()}>{label}</span>}
		</CustomInputCheckboxStyled>
	);
}